import { Button, Grid, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "app/withAppProviders";
import moment, { Moment } from "moment";
import { useState } from "react";
import history from "@history";
import { useSelector } from "react-redux";
import { closeDialog, EDialogType } from "../../store/dialogSlice";

function ExistingNumbersListModal() {
  const [value] = useState<Moment | null>(moment());
  const dispatch = useAppDispatch();
  const [existingNumberShow, setExistingNumberShow] = useState(false);

  const campaignAdded = sessionStorage.getItem("campaignAdded");

  const ExistingNumbers = useSelector(
    (state: any) => state.number.existingNumber
  );
  const InsertedData = useSelector((state: any) => state.number.insertedData);
  const ExistingNumbersList = useSelector(
    (state: any) => state.number.existingNumberList
  );

  const closeDeleteHandler = () => {
    if (campaignAdded === "false") {
      dispatch(closeDialog({ key: EDialogType.ExistingNumbers }));
      history.push("/campaign");
      sessionStorage.setItem("campaignAdded", true as any);
    } else {
      dispatch(closeDialog({ key: EDialogType.ExistingNumbers }));
    }
  };

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item md>
            <Typography variant="h6">
              Total Imported Numbers : {InsertedData}
            </Typography>
            {ExistingNumbers !== 0 ? (
              <Typography variant="h6">
                Total Rejected Numbers : {ExistingNumbers}
              </Typography>
            ) : null}
          </Grid>

          <Grid item>
            {ExistingNumbers !== 0 && (
              <Button
                type="submit"
                variant="contained"
                onClick={() => {
                  setExistingNumberShow(!existingNumberShow);
                }}
              >
                {!existingNumberShow
                  ? "Show Rejected Numbers"
                  : "Hide Rejected Numbers"}
              </Button>
            )}
          </Grid>
        </Grid>
      </Stack>
      {existingNumberShow && (
        <div>
          <Typography variant="h6">Existing Number List :</Typography>
          <div>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <table className="existing-table">
                <tr>
                  <th className="existing-number-table">Name</th>
                  <th className="existing-number-table">Number</th>
                </tr>
                {ExistingNumbersList.map((item: any) => (
                  <tr>
                    <td className="existing-number-table">{item.name}</td>
                    <td className="existing-number-table">{item.number}</td>
                  </tr>
                ))}
              </table>
            </Stack>
          </div>
        </div>
      )}
      <hr />
      <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
        <Button type="submit" onClick={closeDeleteHandler} variant="contained">
          Close
        </Button>
      </Stack>
    </>
  );
}

export default ExistingNumbersListModal;
