import withRouter from "@fuse/core/withRouter";
import { ColDef, ColumnState, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";
import { useAppDispatch } from "app/withAppProviders";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getFiltersByTable } from "../../store/selectors/tableFilterSelector";
import { ETable } from "../../store/tableFilterSlice";
import { EDialogType, openDialog } from "../../store/dialogSlice";
import { stopEditing } from "../store/numberSlice";
import { useNavigate } from "react-router-dom";
import { fetchCampaignTagList } from "../../campaign/store/tagSlice";
import SFFabAction from "app/shared/components/SFFabAction";
function NumberListTable(props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const rowData = useSelector((state: any) => state.tag.items);
  const [data, setData] = useState(rowData);

  const filter = useSelector((state) =>
    getFiltersByTable(state, { table: ETable.NumberList })
  );

  const gridRef = useRef<AgGridReact<any>>(null);

  useEffect(() => {
    dispatch(fetchCampaignTagList({}));
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 150,
      filter: true,
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    { field: "tag_id", headerName: "id" },

    {
      field: "tag",
      headerName: "Contact List Name",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["clear", "apply"],
      },
    },

    {
      field: "count",
      headerName: "Total Number",
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["apply", "reset"],
        closeOnApply: true,
      },
    },

    {
      field: "tag_id",
      cellRenderer: ((data) => {
        const actionButtons = [
          {
            click: (tag_id: any, { tag }) => {
              dispatch(
                openDialog({
                  key: EDialogType.Number,
                  extras: tag_id,
                  title: `Add Contacts To ${tag}`,
                })
              );
            },
            title: "Add",
          },
          {
            click: (tag_id: number) => {
              navigate(`/number/${tag_id}`);
            },
            title: "Visibility",
          },
        ];
        return ActionButtonRenderer(actionButtons);
      })(),
      headerName: "Status",
    },
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    var defaultSortModel: ColumnState[] = [
      { colId: "date", sort: "desc", sortIndex: 0 },
      { colId: "id", sort: "desc", sortIndex: 1 },
    ];
    params.columnApi.applyColumnState({ state: defaultSortModel });

    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 130,
      columnLimits: [{ key: "task", minWidth: 300 }],
    });
  }, []);

  return (
    <>
      <div className="ag-theme-alpine w-full">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          multiSortKey={"ctrl"}
          sideBar={"filters"}
          enableCellTextSelection={true}
        ></AgGridReact>
      </div>
      <SFFabAction
        onClick={() => {
          dispatch(stopEditing());
          dispatch(openDialog({ key: EDialogType.AddNewNumber }));
        }}
      ></SFFabAction>
    </>
  );
}

NumberListTable.propTypes = {};

export default withRouter(NumberListTable);
