import SFForm, { TSFForm } from "app/shared/components/SFForm/SFForm";
import { FieldType } from "app/shared/components/SFForm/SFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { closeDialog, EDialogType } from "../../store/dialogSlice";
import { createTwilio } from "../store/twilioSlice";

function AddTwilioForm() {
  const twilio =
    useSelector((state: any) =>
      state.twilio.items.find((log) => log.id === state.twilio.editing)
    ) || {};

  const [value] = useState<Moment | null>(moment());
  const dispatch = useAppDispatch();

  const onSubmit = (w) => {
    w.id = twilio.id;
    dispatch(createTwilio(w));
    dispatch(closeDialog({ key: EDialogType.Twilio }));
  };

  const form: TSFForm = {
    onSubmit,
    fields: [
      {
        name: "number",
        type: FieldType.Number,
        label: "Twilio Number",
        value: twilio.number || "",
        validation: Yup.string()
          .matches(
            /^\d{10,16}$/,
            "Mobile number should be between 10 and 16 digits"
          )
          .required("Mobile number is required"),
      },
    ],
    resetFormOnSubmit: true,
  };

  return <SFForm {...form}></SFForm>;
}

export default AddTwilioForm;
