import BrowserRouter from "@fuse/core/BrowserRouter";
import FuseAuthorization from "@fuse/core/FuseAuthorization";
import FuseLayout from "@fuse/core/FuseLayout";
import FuseTheme from "@fuse/core/FuseTheme";
import { SnackbarProvider } from "notistack";
import { Auth } from "./auth";
import withAppProviders from "./withAppProviders";
import axios from "axios";
import Init from "./Init";
import "rsuite/dist/rsuite.min.css";
/**
 * Axios HTTP Request defaults
 */
if (localStorage.getItem("token")) {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
    "token"
  )}`;
}

axios.defaults.baseURL = process.env.REACT_APP_TEXTKY_ADMIN_BACKEND;
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common["Content-Type"] = "application/json";
const App = () => {
  return (
    <Auth>
      <BrowserRouter basename="" window="" key="">
        <FuseAuthorization>
          <FuseTheme>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              classes={{
                containerRoot:
                  "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
              }}
            >
              <Init>
                <FuseLayout />
              </Init>
            </SnackbarProvider>
          </FuseTheme>
        </FuseAuthorization>
      </BrowserRouter>
    </Auth>
  );
};
export default withAppProviders(App)();
