import { Button, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import moment, { Moment } from "moment";
import { useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import _ from "@lodash";
import { useParams } from 'react-router-dom';
import { changePassword } from "../store/userProfileSlice";

const schema = yup.object().shape({
  currentPassword: yup.string().required("Please enter your password.").min(8),
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const defaultValues = {
  currentPassword: "",
  password: "",
  passwordConfirm: "",
};

function ChangePasswordModal(props) {
  const dispatch = useDispatch<any>();
  const [value] = useState<Moment | null>(moment());

  const params = useParams();
  // you can find all params from here
  const token = params.token;

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit(userInfo: any) {
    userInfo.token = token;
    dispatch(changePassword(userInfo))
  }

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={4} alignItems={"center"}>
          <Typography variant="h6">
            Are you sure you want to update your password ?
          </Typography>
        </Stack>
        <form
          name="registerForm"
          noValidate
          className="flex flex-col justify-center w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="currentPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-16 mt-7"
                label="Current Password"
                type="password"
                error={!!errors.password}
                helperText={errors?.password?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-16 mt-7"
                label="New Password"
                type="password"
                error={!!errors.password}
                helperText={errors?.password?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
          <Controller
            name="passwordConfirm"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                className="mb-16 mt-7"
                label="Password (Confirm)"
                type="password"
                error={!!errors.passwordConfirm}
                helperText={errors?.passwordConfirm?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Button
            variant="contained"
            color="primary"
            className="w-full mx-auto mt-16"
            aria-label="Register"
            disabled={_.isEmpty(dirtyFields) || !isValid}
            type="submit"
          >
            Update
          </Button>
        </form>
      </Stack>
    </>
  );
}

export default ChangePasswordModal;
