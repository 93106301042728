import withRouter from "@fuse/core/withRouter";
import { ColDef, ColumnState, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";
import SFFabAction from "app/shared/components/SFFabAction";
import { useAppDispatch } from "app/withAppProviders";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fetchCampaignTagList } from "../../campaign/store/tagSlice";
import { EDialogType, openDialog } from "../../store/dialogSlice";
import { getFiltersByTable } from "../../store/selectors/tableFilterSelector";
import { ETable } from "../../store/tableFilterSlice";
import {
  fetchNumber,
  startDeleting,
  stopEditing,
  stopExport
} from "../store/numberSlice";
import { useLocation } from "react-router-dom";
import { getTagById } from "../../campaign/store/tagSelector";
function NumberTable() {
  const location = useLocation();
  const tag_id = +location.pathname.split('/')[2]

  const dispatch = useAppDispatch();
  const tag = useSelector((state: any) => getTagById(tag_id)(state));
  const rowData = useSelector((state: any) => state.number.items);
  const [data, setData] = useState(rowData);

  const filter = useSelector((state) =>
    getFiltersByTable(state, { table: ETable.Number })
  );
  const shouldExport = useSelector((state: any) => state.number.export);
  const gridRef = useRef<AgGridReact<any>>(null);

  useEffect(() => {
    dispatch(fetchNumber({ filter, tag_id }));
    dispatch(fetchCampaignTagList({}));
  }, [dispatch, filter]);

  useEffect(() => {
    if (gridRef && gridRef.current && shouldExport) {
      gridRef.current.api.exportDataAsCsv({
        fileName: "Number.csv",
        columnKeys: columnDefs
          .filter((col) => col.field !== "id")
          .map((col) => `${col.field}`),
      });
      dispatch(stopExport());
    }
  }, [shouldExport]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 150,
      filter: true,
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    { field: "id" },

    {
      field: "name",
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['clear', 'apply'],
      },
    },

    {
      field: "number",
      filter: 'agNumberColumnFilter',
      filterParams: {
        buttons: ['apply', 'reset'],
        closeOnApply: true,
      },
    },

    {
      field: "id",
      cellRenderer: ActionButtonRenderer([
        {
          click: (id) => {
            dispatch(startDeleting(id))
            dispatch(openDialog({ key: EDialogType.NumberConfirmation }));
          },
          title: "Delete",
        },
      ]),
      headerName: "Actions",
    },
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    var defaultSortModel: ColumnState[] = [
      { colId: "date", sort: "desc", sortIndex: 0 },
      { colId: "id", sort: "desc", sortIndex: 1 },
    ];
    params.columnApi.applyColumnState({ state: defaultSortModel });

    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 130,
      columnLimits: [{ key: "task", minWidth: 300 }],
    });
  }, []);

  return (
    <>
      <div className="ag-theme-alpine w-full">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          multiSortKey={"ctrl"}
          sideBar={"filters"}
          enableCellTextSelection={true}
        ></AgGridReact>
      </div>
      <SFFabAction
        onClick={() => {
          dispatch(stopEditing());
          dispatch(openDialog({ key: EDialogType.Number, extras: tag_id, title: `Add Contacts To ${tag.tag}` }));
        }}
      ></SFFabAction>
    </>
  );
}

NumberTable.propTypes = {};

export default withRouter(NumberTable);
