import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import { closeDialog, EDialogType } from "../../store/dialogSlice";
import axios from "axios";

export type TwilioPurchase = {
  id: number;
  phone_number: number;
};

export type TwilioPurchaseState = {
  items: TwilioPurchase[];
  editing: number | boolean;
  filter: { [key: string]: number };
  export: boolean;
  sidebar: boolean;
  loader: boolean;
};

export const fetchTwilioAvaliableNumbers = createAsyncThunk(
  "twilio/avaliable-number",
  async (filters: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const filter = filters.reduce((pv, cv) => {
      pv[cv.key] = cv.value;
      return pv;
    }, {});

    try {
      const response = await axios.get(`/twilio/available-number`, {
        params: {
          ...filter,
        },
      });
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({
          message:
            err.response.data.error === "country_code field required"
              ? "Please select the country code"
              : err.response.data.error,
          variant: "error",
        })
      );
    }
  }
);

export const purchaseTwilioNumbers = createAsyncThunk(
  "twiliopurchase/fetch",

  async (phone_number: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const numbers = phone_number;
    const finalNumber = numbers.replace(/\D/g, "");
    try {
      const response = await axios.get(`/twilio/${finalNumber}/purchase`);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      dispatch(closeDialog({ key: EDialogType.PurchaseConfirmation }));
      return response.data;
    } catch (err: any) {
      dispatch(closeDialog({ key: EDialogType.PurchaseConfirmation }));
      dispatch(
        showMessage({ message: err.response.data.data, variant: "error" })
      );
    }
  }
);

const initialState: TwilioPurchaseState = {
  items: [],
  editing: false,
  filter: {},
  export: false,
  sidebar: false,
  loader: true,
};

const twiliopurchaseSlice = createSlice({
  name: "twiliopurchase",
  initialState,
  reducers: {},
  extraReducers: {
    [`${fetchTwilioAvaliableNumbers.pending}`]: (state, action) => {
      state.items = [];
      state.loader = false;
    },
    [`${fetchTwilioAvaliableNumbers.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
      state.loader = false;
    },
    [`${purchaseTwilioNumbers.pending}`]: (state, action) => {
      state.loader = true;
    },
    [`${purchaseTwilioNumbers.fulfilled}`]: (state, action) => {
      state.loader = false;
    },
  },
});

export default twiliopurchaseSlice.reducer;
