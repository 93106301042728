import { RouteConfig } from "../../../shared/types";
import PurchaseNumber from "./twilioList/TwilioPurchase";
import Twilios from "./twilioList/Twilios";
import TwilioSetting from "./twilioList/TwilioSettings";

const TwilioRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "twilio/numbers",
      element: <Twilios />,
      permissions: [""],
    },

    {
      path: "twilio/settings",
      element: <TwilioSetting />,
      permissions: [""],
    },

    {
      path: "twilio/purchase",
      element: <PurchaseNumber />,
      permissions: [""],
    },
  ],
};

export default TwilioRouteConfig;
