import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function LogsHead(props) {
  const location = useLocation();
  const Id = location.pathname.split("/")[2];

  const Logs: any =
    useSelector((state: any) =>
      state.campaign?.items?.find((log) => log.id === state.campaign.editing)
    ) || {};

  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <div className="flex items-center">
        <Icon
          component={motion.span}
          initial={{ scale: 0 }}
          animate={{ scale: 1, transition: { delay: 0.2 } }}
          className="text-24 md:text-32"
        >
          lists
        </Icon>

        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          className="hidden sm:flex text-16 md:text-24 mx-12 font-semibold"
        >
          {Logs.campaign_name && Id ? `Logs of ${Logs.campaign_name}` : "Logs"}
        </Typography>
      </div>
    </div>
  );
}

export default LogsHead;
