import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";

export type Verification = {
  id: number;
  token: string;
};

export type VerificationState = {
  items: any;
};

export const emailVerification = createAsyncThunk(
  "/email-verification",
  async (token: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const autoRedirect = () => {
      const timeout = setTimeout(() => {
        window.location.replace('/login');
      }, 3000);

      return () => clearTimeout(timeout);
    }

    try {
      const response = await axios.post(`/email-verification/${token}`);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      autoRedirect();
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

const initialState: VerificationState = {
  items: []
};

const verificationSlice = createSlice({
  name: "verification",
  initialState,
  reducers: {},
  extraReducers: {}
});

export const { } = verificationSlice.actions;
export default verificationSlice.reducer;
