import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";
import history from "@history";

export type Campaign = {
  id: number;
  status: "running" | "paused" | "completed" | "pending";
};

export type CampaignState = {
  items: Campaign[];
  stepperItems: Campaign[];
  listingNumbers: Campaign[];
  suggstionText: any;
  messageValidation: any;
  tagItems: any;
  deleting: number | boolean;
  editing: number | boolean;
  filter: { [key: string]: number };
  export: boolean;
  sidebar: boolean;
};

export const campaignMessageSuggestion = createAsyncThunk(
  "campaign/checkMessageValidation",
  async (campaign: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const url = `/campaign/message`;

    try {
      const response = await axios.post(url, campaign);
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const createCampaign = createAsyncThunk(
  "campaign/create",
  async (campaign: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const Updatecampaign: any = {
      campaign_name: campaign.campaign_name,
    };

    const url = campaign.id
      ? `campaign/update-message-service/${campaign.id}`
      : "/campaign/creating-message-services-usecase";

    try {
      const response = await axios.post(
        url,
        campaign.id ? Updatecampaign : campaign
      );
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return response;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const createCampaignStep2 = createAsyncThunk(
  "campaign/create",
  async (campaign: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const startTime = new Date();
    const endTime = new Date();

    startTime.setHours(
      campaign?.cron_time?.split(":")[0],
      campaign?.cron_time?.split(":")[1],
      0,
      0
    );
    endTime.setHours(
      campaign?.end_time?.split(":")[0],
      campaign?.end_time?.split(":")[1],
      0,
      0
    );

    campaign.cron_time = `${startTime
      .getUTCHours()
      .toString()
      .padStart(2, "0")}:${startTime
      .getUTCMinutes()
      .toString()
      .padStart(2, "0")}`;
    campaign.end_time = `${endTime
      .getUTCHours()
      .toString()
      .padStart(2, "0")}:${endTime
      .getUTCMinutes()
      .toString()
      .padStart(2, "0")}`;

    const url = campaign.id
      ? `/campaign/update-message-service/${campaign.id}`
      : "/campaign/add-numbers-to-portal";

    try {
      const response = await axios.post(url, campaign);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      history.push("/campaign");
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const fetchCampaign = createAsyncThunk(
  "campaign/fetch",
  async (filters: any) => {
    const filter = filters.reduce((pv, cv) => {
      pv[cv.key] = cv.value;
      return pv;
    }, {});

    const response = await axios.get("/campaign?page=1&size=50");
    return response.data;
  }
);

export const startCampaign = createAsyncThunk(
  "campaign/start",
  async (id: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const response = await axios.post(`/campaign/${id}/start`, null);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return { id };
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const pauseCampaign = createAsyncThunk(
  "campaign/pause",
  async (id: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const response = await axios.post(`/campaign/${id}/pause`, null);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return { id };
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaign/delete",
  async (id: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const response = await axios.delete(`/campaign/${id}`);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return { id };
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const SuggestionFromChatGPT = createAsyncThunk(
  "/suggestion-text",
  async (searchtext: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const { text } = searchtext;
    const body = {
      text,
    };

    try {
      const response = await axios.post("/campaign/message/suggestion", body);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

const initialState: CampaignState = {
  items: [],
  stepperItems: [],
  listingNumbers: [],
  tagItems: null,
  suggstionText: null,
  messageValidation: null,
  editing: false,
  deleting: false,
  filter: {},
  export: false,
  sidebar: false,
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    startDeleting: (state, action) => {
      state.deleting = action.payload;
    },
    startEditing: (state, action) => {
      state.editing = action.payload;
    },
    stopEditing: (state) => {
      state.editing = false;
    },
    startExport: (state) => {
      state.export = true;
    },
    stopExport: (state) => {
      state.export = false;
    },
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
    resetSuggestions: (state) => {
      state.suggstionText = null;
    },
  },

  extraReducers: {
    [`${createCampaign.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex(
        (it) => it.id === action.payload.data.id
      );
      if (index >= 0) {
        state.items[index] = action.payload.data;
      } else {
        state.items.push(action.payload.data);
        state.stepperItems = action.payload.data;
      }
    },
    [`${campaignMessageSuggestion.fulfilled}`]: (state, action) => {
      state.messageValidation = action.payload.message;
    },
    [`${fetchCampaign.fulfilled}`]: (state, action) => {
      state.items = action.payload.items;
    },
    [`${SuggestionFromChatGPT.fulfilled}`]: (state, action) => {
      state.suggstionText = action.payload.data[0].text;
    },
    [`${deleteCampaign.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex((it) => it.id === action.payload.id);
      state.items.splice(index, 1);
    },
    [`${startCampaign.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex((it) => it.id === action.payload.id);
      state.items[index].status = "running";
    },
    [`${pauseCampaign.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex((it) => it.id === action.payload.id);
      state.items[index].status = "paused";
    },
  },
});

export const {
  startEditing,
  startDeleting,
  stopEditing,
  startExport,
  stopExport,
  toggleSidebar,
  resetSuggestions,
} = campaignSlice.actions;
export default campaignSlice.reducer;
