import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export type TPeople = {
  id: number
  userDetail: any
}

export type TPeopleState = {
  items: TPeople[]
  editing: number | boolean,
  filter: { [key: string]: number },
  export: boolean,
  sidebar: boolean
}

export const createPeople = createAsyncThunk(
  'people/create',
  async (people: TPeople) => {
    const response = await axios.post(`/user/${people.id || ''}`, people);
    return response.data
  }
)

export const updateUserDetails = createAsyncThunk(
  'people/updateUserDetails',
  async (people: TPeople) => {
    const response = await axios.post(`/user/update-user-details/${people.id}`, people);
    return response.data
  }
)

export const fetchPeople = createAsyncThunk(
  'people/fetch',
  async (filters: any) => {
    const filter = filters.reduce((pv, cv) => {
      pv[cv.key] = cv.value
      return pv;
    }, {})
    const response = await axios.get('/user', {
      params: {
        filter
      }
    });
    return response.data
  }
)

const initialState: TPeopleState = {
  items: [],
  editing: false,
  filter: {},
  export: false,
  sidebar: false
};

const peopleSlice = createSlice({
  name: 'people',
  initialState,
  reducers: {
    startEditing: (state, action) => {
      state.editing = action.payload
    },
    stopEditing: (state) => {
      state.editing = false
    },
    startExport: (state) => {
      state.export = true
    },
    stopExport: (state) => {
      state.export = false
    },
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar
    }
  },
  extraReducers: {
    [`${createPeople.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex(it => it.id === action.payload.id)
      if (index >= 0) {
        state.items[index] = action.payload
      } else {
        state.items.push(action.payload);
      }
    },
    [`${updateUserDetails.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex(it => it.id === action.payload.id)
      if (index >= 0) {
        state.items[index] = action.payload
      }
    },
    [`${fetchPeople.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
    }
  }
});

export const { startEditing, stopEditing, startExport, stopExport, toggleSidebar } = peopleSlice.actions;
export default peopleSlice.reducer;
