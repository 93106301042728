import { RouteConfig } from "../../../shared/types";
import Verification from "./verification/Verification";

const VerificationRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: "verification/:token",
      element: <Verification />,
      permissions: ["worklog_read"],
    },
  ],
};

export default VerificationRouteConfig;
