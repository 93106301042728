import SettingTimePicker from "./SettingTimePicker";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";

const SettingTable = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
      >
        <Typography
          color="inherit"
          className="text-32 sm:text-14 font-semibold leading-tight widget flex w-full p-12"
        >
          Set Time to send messages of running campaign.
        </Typography>
        <div className="ml-10">
          <SettingTimePicker />
        </div>
      </motion.div>
    </>
  );
};

export default SettingTable;
