import * as React from "react";
import { useEffect } from "react";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import { useAppDispatch } from "app/withAppProviders";
import { useSelector } from "react-redux";
import { fetchTime, sendCronTime } from "../store/settingSlice";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

export default function SettingTimePicker() {
  const dispatch = useAppDispatch();
  const [value, setValue] = React.useState<any>(dayjs("2014-08-18T21:11:54"));
  const rowData = useSelector((state: any) => state.setting.items);

  useEffect(() => {
    setValue(rowData ? rowData.user_time?.toString() + "Z" : null);
  }, [rowData]);

  useEffect(() => {
    dispatch(fetchTime(null));
  }, []);

  const handleChange = (newValue: any) => {
    setValue(newValue);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <TimePicker
              label="Time"
              value={new Date(value)}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
      >
        <Button
          variant="contained"
          color="primary"
          className="w-full mt-10 mx-auto "
          onClick={() => {
            dispatch(sendCronTime(value?.$d.toISOString()));
            dispatch(fetchTime({}));
          }}
        >
          Update
        </Button>
      </motion.div>
    </>
  );
}
