import withRouter from "@fuse/core/withRouter";
import TableFilters from "app/shared/components/TableFilters";
import { ETableFilter } from "app/shared/components/TableFilters/TableFilters";
import { useAppDispatch } from "app/withAppProviders";
import { useEffect } from "react";
import { ETable } from "../../store/tableFilterSlice";
import { fetchCountryCode } from "../store/twiliocountrycodeSlice";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import { Stack, Typography } from "@mui/material";

function TwilioPurchaseToolbar(props) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchCountryCode());
  }, []);

  const filters = [
    {
      type: ETableFilter.ReduxDropdown,
      reduxObjectName: "countrycode",
      name: "country_code",
      label: "Search by Country",
      labelKey: "tag",
      labelcode: "code",
    },
    {
      type: ETableFilter.SearchTextItemFilter,
      name: "area_code",
      label: "Search by area code",
      labelKey: "tag",
      labelcode: "code",
    },
    {
      type: ETableFilter.SearchTextItemFilter,
      name: "contains",
      label: "Search by numbers",
    },
    {
      type: ETableFilter.ReduxDropdown,
      reduxObjectName: "enum",
      addtionalOptions: [
        { id: "exclude_all_address_required", label: "None" },
        {
          id: "exclude_local_address_required",
          label: "Exclude local requirements",
        },
        {
          id: "exclude_foreign_address_required",
          label: "Exclude foreign requirements",
        },
      ],
      name: "exclude",
      label: (
        <>
          <Stack direction="row" spacing={1}>
            <p>Address requirements</p>
            <p>
              <Tooltip title="Some local authorities require you to provide an address before purchasing a phone number.">
                <InfoIcon fontSize="small" />
              </Tooltip>
            </p>
          </Stack>
        </>
      ),
    },
    {
      type: ETableFilter.SearchCheckItemFilter,
      name: "beta",
      label: "Beta numbers",
    },
  ];

  return (
    <div className="flex w-full items-center justify-between">
      <TableFilters filters={filters} table={ETable.TwilioPurchaseNumbers} />
    </div>
  );
}

export default withRouter(TwilioPurchaseToolbar);
