import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

function SFMultiSelect(props) {
  const {
    error,
    name,
    label,
    id,
    touched,
    value: propVal,
    handleBlur,
    items,
    setFieldValue,
    labelKey = "name",
    disabled,
  } = props;
  const [value, setValue] = useState<any[]>(propVal);
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      getOptionLabel={(option: any) => option[labelKey] || ""}
      renderInput={(props) => (
        <TextField
          {...props}
          label={label || ""}
          error={error && touched && error}
          variant="standard"
        />
      )}
      key={name}
      id={id || name}
      fullWidth
      multiple={true}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      disabled={disabled}
      value={items.filter((option) => value.includes(option.id))}
      isOptionEqualToValue={(option, value) => {
        return option.id === value;
      }}
      onChange={(_, value) => {
        setFieldValue(name, value ? value.map((it) => it.id) : value);
        setValue(value ? value.map((it) => it.id) : value);
      }}
      onBlur={handleBlur}
      options={items}
    />
  );
}

export default SFMultiSelect;
