import { RouteConfig } from "../../../shared/types";
import Logs from "./loglist/logs";

const LogsRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: "logs",
      element: <Logs />,
      permissions: [""],
    },
    {
      path: "logs/:id",
      element: <Logs />,
      permissions: [""],
    },
  ],
};

export default LogsRouteConfig;
