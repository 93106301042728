import { Autocomplete, TextField } from "@mui/material"
import { getFilterByTableAndKey } from "app/main/apps/store/selectors/tableFilterSelector"
import { addFilter, removeFilter } from "app/main/apps/store/tableFilterSlice"
import { useAppDispatch } from "app/withAppProviders"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { sentenceCase } from "sentence-case"

function ReduxDropdown({ filter, table }) {
  const dispatch = useAppDispatch()
  const { reduxObjectName, name, id, label, labelKey = 'name', labelcode = "id", addtionalOptions = [] } = filter
  const items = useSelector((state: any) => state[reduxObjectName].items) || []
  const selectedValue = useSelector(state => getFilterByTableAndKey(state, { table, key: name }))
  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState('')
  const options = items.map(it => ({ id: it[labelcode], label: it[labelKey] }))
  const allOptions = [...addtionalOptions, ...options];

  useEffect(() => {
    setValue(allOptions.find(it => it.id === selectedValue))
  }, [selectedValue])

  const handleChange = (event, selected) => {
    setValue(selected)
    if (selected) {
      dispatch(addFilter({
        key: table,
        filter: {
          key: name,
          value: selected.id
        }
      }))
    } else {
      dispatch(removeFilter({ key: table, filter: { key: name } }))
    }
  }

  return <>
    <Autocomplete
      disablePortal
      id={id || name}
      options={allOptions}
      getOptionLabel={(option) => option.label}
      sx={{ minWidth: 200 }}
      onChange={handleChange}
      value={value}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderInput={(params) => <TextField {...params} label={label || sentenceCase(name)} />}
    />
  </>
}

export default ReduxDropdown