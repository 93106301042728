import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from 'react-redux';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
    },
  },
};

export default function WorklogChartByCategory() {
  const dashBoard: any = useSelector((state: any) =>
    (state.dashboard.items)
  );
  const dateData = dashBoard?.weeklyData?.map((one: any) => one.sent)
  const labels = dashBoard?.weeklyData?.map((one: any) => one.date)

  const data = {
    labels,
    datasets: [
      {
        label: "Past 7 Days Data",
        data: dateData,
        backgroundColor: "#575757",
      },
    ],
  };

  return <Bar options={options} data={data} />;
}
