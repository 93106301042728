import { useSelector } from "react-redux";
import MemberCard from "./MemberCard";
import Dashboard from "../dashboard/Dashboard";

export default function Team() {
  const team =
    useSelector((state: any) =>
      [...state.team.items].sort((a, b) => (a.id > b.id ? 1 : -1))
    ) || [];
  return (
    <>
      <div className="flex flex-row flex-wrap sm:flex sm:flex-row pb-32">
        <h1>Team Data not found</h1>
        {/* <Dashboard /> */}
        {team &&
          [...team].map((member, index) => (
            <MemberCard member={member} key={index} />
          ))}
      </div>
    </>
  );
}
