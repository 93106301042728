import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const Root: any = styled("div")(({ theme }) => ({
  "& > .logo-icon": {
    transition: theme.transitions.create(["width", "height"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  "& > .badge, & > .logo-text": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo() {
  return (
    <Root className="flex items-center">
      <Link to={"/dashboard"} role="button">
        <img
          className="logo-icon w-100 h-80 pt-5"
          src="assets/images/logo2.png"
          alt="logo"
        />
      </Link>
    </Root>
  );
}

export default Logo;
