import { useEffect } from "react";
import { motion } from "framer-motion";
import Typography from "@mui/material/Typography";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { emailVerification } from "../store/verificationSlice";

function VerificationPage() {
  const dispatch = useDispatch<any>();

  const params = useParams();
  // you can find all params from here
  const token = params.token;

  useEffect(() => {
    dispatch(emailVerification(token));
  });

  return (
    <div className="flex flex-col flex-auto p-16 sm:p-24 md:flex-row md:p-0 overflow-hidden">
      <div className="flex flex-col d-flex grow-0 items-center  p-16 text-center md:px-128 md:items-start md:shrink-0 md:flex-1 md:text-left">
        <img
          className="row logo-icon w-100 h-60"
          src="assets/images/logo1.png"
          alt="logo"
        />
        <motion.div
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
        ></motion.div>

        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
        >
          <Typography className="text-32 sm:text-44 font-semibold leading-tight">
            Verification Success
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { delay: 0.3 } }}
        >
          <Typography variant="subtitle1" className="mt-32 font-medium">
            Your email has been verified successfully
          </Typography>
        </motion.div>
        <div className="flex flex-col items-center justify-center pt-32 pb-24">
          <Link className="font-normal" to="/login">
            Go back to login
          </Link>
        </div>
      </div>
    </div>
  );
}

export default VerificationPage;
