import { Navigate } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import People from "./admin/people/People";
import Team from "./team/Team";
import UserDetail, { EPeopleSection } from "./admin/user-detail/UserDetail";
import { RouteConfig } from "app/shared/types";

const PeopleRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "/people",
      element: <Navigate to="team" replace />,
      permissions: ["user_read"],
    },
    {
      path: "people/dashboard",
      element: <Dashboard />,
      permissions: ["user_read"],
    },
    {
      path: "people/team",
      element: <Team />,
      permissions: ["user_public_list"],
    },
    {
      path: "people/primary-details",
      element: <People />,
      permissions: ["user_read"],
    },
    {
      path: "people/personal-details",
      element: <UserDetail section={EPeopleSection.PersonalDetails} />,
      permissions: ["user_read"],
    },
    {
      path: "people/joining-details",
      element: <UserDetail section={EPeopleSection.JoiningDetails} />,
      permissions: ["user_read"],
    },
    {
      path: "people/identity",
      element: <UserDetail section={EPeopleSection.Identity} />,
      permissions: ["user_read"],
    },
    {
      path: "people/background-check",
      element: <UserDetail section={EPeopleSection.BackgroundCheck} />,
      permissions: ["user_read"],
    },
    {
      path: "people/misc",
      element: <UserDetail section={EPeopleSection.Misc} />,
      permissions: ["user_read"],
    },
    {
      path: "people/addresses",
      element: <UserDetail section={EPeopleSection.Address} />,
      permissions: ["user_read"],
    },
    {
      path: "people/education",
      element: <UserDetail section={EPeopleSection.Education} />,
      permissions: ["user_read"],
    },
    {
      path: "people/bank-details",
      element: <UserDetail section={EPeopleSection.BankDetails} />,
      permissions: ["user_read"],
    },
  ],
};

export default PeopleRouteConfig;
