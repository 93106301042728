import { Button, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "app/withAppProviders";
import moment, { Moment } from "moment";
import { useState } from "react";
import history from "@history";
import { closeDialog, EDialogType } from "../../store/dialogSlice";
import { purchaseTwilioNumbers } from "../store/twilioPurchaseSlice";

function PurchaseNumberConfirmationModal(props) {
  const [value] = useState<Moment | null>(moment());
  const dispatch = useAppDispatch();

  const PurchaseNumberHandler = async () => {
    dispatch(purchaseTwilioNumbers(props.extras)).then((res) => {
      const userDetails = { ...res.payload.data };
      if (userDetails.is_purchase_number_added === false) {
        history.push("twilio/purchase");
      } else if (userDetails.is_number_added === false) {
        history.push("/numbers/list");
      } else if (userDetails.is_campaign_added === false) {
        history.push("/campaign");
      }
    });
  };

  const closeDeleteHandler = () => {
    dispatch(closeDialog({ key: EDialogType.PurchaseConfirmation }));
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={4} alignItems={"center"}>
          <Typography variant="h6">
            Are you sure want to Purchase {props.extras} ?
          </Typography>
        </Stack>
      </Stack>
      <hr />
      <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
        <Button type="submit" onClick={closeDeleteHandler} variant="contained">
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={PurchaseNumberHandler}
          variant="contained"
        >
          Confirm
        </Button>
      </Stack>
    </>
  );
}

export default PurchaseNumberConfirmationModal;
