import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";

export type Dashboard = {
  id: number;
  date: number;
  count: number;
};

export type DashboardState = {
  items: {};
  weeklyData: Dashboard[];
  activeNumber: number;
  totalNumber: number;
  twilio_balance: number;
  twilio_numbers: number;
  filter: { [key: string]: number };
  export: boolean;
  sidebar: boolean;
};

export const fetchDashboardDetails: any = createAsyncThunk(
  "dashboard/fetch",
  async (data: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const response = await axios.get("/dashboard");
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

const initialState: DashboardState = {
  items: {},
  activeNumber: 0,
  totalNumber: 0,
  twilio_balance: 0,
  twilio_numbers: 0,
  weeklyData: [],
  filter: {},
  export: false,
  sidebar: false,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    startExport: (state) => {
      state.export = true;
    },
    stopExport: (state) => {
      state.export = false;
    },
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
  },

  extraReducers: {
    [`${fetchDashboardDetails.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
      state.activeNumber = action.payload.data.totalActiveCampaign;
      state.totalNumber = action.payload.data.totalNumbers;
      state.twilio_balance = action.payload.data.current_twilio_balance;
      state.twilio_numbers = action.payload.data.total_Twilio_purchase_number;
      state.weeklyData = action.payload.data.weeklyData;
    },
  },
});

export const { startExport, stopExport, toggleSidebar } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
