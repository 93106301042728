import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { DatePicker } from "@mui/lab";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
  FilledInput,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Radio,
  FormLabel,
  RadioGroup,
  Button,
  Stack,
  Input,
  Typography,
} from "@mui/material";
import { FieldArray } from "formik";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { sentenceCase } from "sentence-case";
import SFIcon from "../SFIcon";
import FileUpload from "./fields/FileUpload";
import SFAutocomplete from "./SFAutocomplete";
import SFError from "./SFError";
import SFMultiSelect from "./SFMultipleSelect";

export enum FieldType {
  Date,
  Text,
  LongText,
  Number,
  StaticSelect,
  DynamicSelect,
  Array,
  FilledInput,
  Textarea,
  FileUpload,
  button,
  MessageBox,
  RadioSelect,
  SuggestionButton,
  TextBox,
  SelectTime,
  SelectFromButton,
  Password,
  MultiSelectDropdown,
  LongTextField,
}

export type TSFFormItem = {
  name: string;
  type: FieldType;
  id?: string;
  label?: string;
  value?: any;
  handleChange?: any;
  endAdornment?: any;
  handleBlur?: any;
  handleClick?: any;
  validation?: any;
  error?: any;
  touched?: boolean;
  setFieldValue?: any;
  inputProps?: any;
  reduxObjectName?: any;
  options?: any;
  fields?: TSFFormItem[];
  labelKey?: string;
  isHelperTextShow?: boolean;
  disabled?: boolean;
};

function RenderFormItem(props: TSFFormItem) {
  const {
    type,
    value,
    handleChange,
    error,
    touched,
    handleBlur,
    handleClick,
    setFieldValue,
    name,
    label,
    id,
    inputProps,
    reduxObjectName,
    options,
    fields,
    isHelperTextShow,
    disabled,
  } = props;
  const items = useSelector(
    (state: any) => (state[reduxObjectName || name] || {}).items || []
  );

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  switch (type) {
    case FieldType.Array:
      return (
        <>
          <FieldArray
            key={`${name}`}
            name={name}
            render={(arrayHelpers: any) => {
              return (
                <div>
                  {value.map((singleObject, index) => {
                    return (
                      <div key={`${name}-${index}`}>
                        <div>
                          {fields &&
                            fields.map((field) => {
                              return (
                                <Stack
                                  direction="row"
                                  spacing={0}
                                  alignItems={"center"}
                                >
                                  <SFFormItem
                                    name={`${name}[${index}][${field.name}]`}
                                    label={field.label}
                                    type={field.type}
                                    key={`${name}[${index}][${field.name}]`}
                                    value={value[index][field.name]}
                                    handleChange={handleChange}
                                    handleClick={handleClick}
                                    error={
                                      ((error || [])[index] || {})[field.name]
                                    }
                                    touched={!!touched}
                                    handleBlur={handleBlur}
                                    setFieldValue={setFieldValue}
                                    inputProps={field.inputProps}
                                    options={field.options}
                                    reduxObjectName={field.reduxObjectName}
                                    isHelperTextShow={!!isHelperTextShow}
                                    disabled={!!disabled}
                                  />
                                  {index === 0 ? (
                                    <div>
                                      <SFIcon
                                        icon={"add_circle"}
                                        onClick={() =>
                                          arrayHelpers.push(
                                            fields?.reduce((pv, cv) => {
                                              pv[cv.name] = cv.value;
                                              return pv;
                                            }, {})
                                          )
                                        }
                                      ></SFIcon>
                                    </div>
                                  ) : (
                                    <div>
                                      <Stack
                                        direction="row"
                                        spacing={0}
                                        alignItems={"center"}
                                      >
                                        <SFIcon
                                          icon={"remove_circle"}
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        ></SFIcon>
                                        <SFIcon
                                          icon={"add_circle"}
                                          onClick={() =>
                                            arrayHelpers.push(
                                              fields?.reduce((pv, cv) => {
                                                pv[cv.name] = cv.value;
                                                return pv;
                                              }, {})
                                            )
                                          }
                                        ></SFIcon>
                                      </Stack>
                                    </div>
                                  )}
                                </Stack>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }}
          />
        </>
      );
    case FieldType.MessageBox:
      return (
        <>
          <FieldArray
            key={`${name}`}
            name={name}
            render={(arrayHelpers: any) => {
              return (
                <div>
                  {value.map((singleObject, index) => {
                    return (
                      <div>
                        {fields &&
                          fields.map((field) => {
                            return (
                              <SFFormItem
                                name={`${name}[${index}][${field.name}]`}
                                label={field.label}
                                type={field.type}
                                key={`${name}[${index}][${field.name}]`}
                                value={value[index][field.name]}
                                handleChange={handleChange}
                                handleClick={handleClick}
                                error={((error || [])[index] || {})[field.name]}
                                touched={!!touched}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                inputProps={field.inputProps}
                                options={field.options}
                                reduxObjectName={field.reduxObjectName}
                                disabled={!!disabled}
                              />
                            );
                          })}
                      </div>
                    );
                  })}
                </div>
              );
            }}
          />
        </>
      );
    case FieldType.Date:
      return (
        <>
          <DatePicker
            key={name}
            label={label || sentenceCase(name)}
            value={value}
            onChange={(value) => {
              return setFieldValue(
                name,
                moment(value).format("YYYY-MM-DD"),
                true
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                id={id || name}
                disabled={disabled}
                variant="standard"
                fullWidth
                margin="dense"
              />
            )}
          />
          <SFError>{error && touched && error}</SFError>
        </>
      );
    case FieldType.StaticSelect:
      return (
        <>
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">
              {label || sentenceCase(name)}
            </InputLabel>
            <Select
              key={name}
              displayEmpty
              id={id || name}
              name={name}
              fullWidth
              multiple
              classes={{ select: "py-8" }}
              value={value}
              onChange={handleChange}
              onBlur={handleBlur}
              error={error && touched && error}
              disabled={disabled}
            >
              {options.map((item) => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{error && touched && error}</FormHelperText>
          </FormControl>
        </>
      );
    case FieldType.MultiSelectDropdown:
      return (
        <>
          <FormControl fullWidth variant="standard">
            <SFMultiSelect {...props} items={items}></SFMultiSelect>
            <FormHelperText
              sx={{ fontSize: 12 }}
              error={error && touched && error}
              disabled={disabled}
            >
              {error && touched && error}
            </FormHelperText>
          </FormControl>
        </>
      );

    case FieldType.DynamicSelect:
      return (
        <>
          <FormControl fullWidth variant="standard">
            <SFAutocomplete {...props} items={items}></SFAutocomplete>
            <SFError>{error && touched && error}</SFError>
          </FormControl>
        </>
      );
    case FieldType.Password:
      return (
        <>
          <TextField
            key={name}
            margin="dense"
            id={id || name}
            label={label || sentenceCase(name)}
            type="password"
            fullWidth
            value={value}
            name={name}
            onChange={handleChange}
            variant="standard"
            onBlur={handleBlur}
            error={error && touched && error}
            helperText={error && touched && error}
            disabled={disabled}
          />
        </>
      );
    case FieldType.Text:
      return (
        <>
          <TextField
            key={name}
            margin="dense"
            id={id || name}
            label={label || sentenceCase(name)}
            type="text"
            fullWidth
            value={value}
            name={name}
            onChange={handleChange}
            variant="standard"
            onBlur={handleBlur}
            error={error && touched && error}
            helperText={
              isHelperTextShow
                ? (error && touched && error) || (
                    <a
                      href="https://support.twilio.com/hc/en-us/articles/1260801864489-How-do-I-register-to-use-A2P-10DLC-messaging-"
                      target="_blank"
                    >
                      How to register your brand in Twilio ?
                    </a>
                  )
                : ""
            }
            disabled={disabled}
          />
        </>
      );
    case FieldType.FilledInput:
      return (
        <>
          <InputLabel htmlFor="filled-adornment-password" className="form-item">
            {label || sentenceCase(name)}
          </InputLabel>
          <Input
            className="mt-5 filled-input-custom"
            key={name}
            margin="dense"
            id={id || name}
            size="medium"
            type={showPassword ? "text" : "password"}
            fullWidth
            value={value}
            name={name}
            onChange={handleChange}
            disabled={disabled}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  className="form-item"
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            onBlur={handleBlur}
            error={error && touched && error}
          />
        </>
      );
    case FieldType.TextBox:
      return (
        <>
          <InputLabel htmlFor="filled-adornment-password">
            {label || sentenceCase(name)}
          </InputLabel>
          <FilledInput
            className="mt-5"
            key={name}
            margin="dense"
            id={id || name}
            size="medium"
            type="text"
            fullWidth
            value={value}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error && touched && error}
            disabled={disabled}
          />
        </>
      );
    case FieldType.LongText:
      return (
        <>
          <TextField
            key={name}
            margin="dense"
            id={id || name}
            label={label || sentenceCase(name)}
            type="text"
            fullWidth
            multiline
            maxRows={5}
            value={value}
            name={name}
            onChange={handleChange}
            variant="standard"
            onBlur={handleBlur}
            error={error && touched && error}
            helperText={
              isHelperTextShow
                ? (error && touched && error) ||
                  (value &&
                    `character length: ${value.length} (Page : ${Math.ceil(
                      value.length / 160
                    )})`)
                : ""
            }
            disabled={disabled}
          />
        </>
      );
    case FieldType.LongTextField:
      return (
        <>
          <TextField
            key={name}
            margin="dense"
            id={id || name}
            label={label || sentenceCase(name)}
            type="text"
            fullWidth
            multiline
            maxRows={5}
            value={value}
            name={name}
            onChange={handleChange}
            variant="standard"
            onBlur={handleBlur}
            error={error && touched && error}
            helperText={
              (error && touched && error) ||
              (value && `Character length : ${value.length}`)
            }
            disabled={disabled}
          />
        </>
      );
    case FieldType.Number:
      return (
        <>
          <TextField
            key={name}
            margin="dense"
            name={name}
            id={id || name}
            label={label || sentenceCase(name)}
            type="number"
            value={value}
            fullWidth
            variant="standard"
            onChange={handleChange}
            inputProps={inputProps}
            onBlur={handleBlur}
            error={error && touched && error}
            helperText={error && touched && error}
            disabled={disabled}
          />
        </>
      );
    case FieldType.RadioSelect:
      return (
        <>
          <FormLabel id="demo-row-radio-buttons-group-label" className="mt-5">
            {" "}
            {label || sentenceCase(name)}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name={name}
            id={id || name}
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="True"
              control={<Radio disabled={disabled} />}
              label="True"
            />
            <FormControlLabel
              value="False"
              control={<Radio disabled={disabled} />}
              label="False"
            />
          </RadioGroup>
        </>
      );
    case FieldType.FileUpload:
      return (
        <>
          <FileUpload {...props} />
        </>
      );
    case FieldType.button:
      return (
        <>
          <div>
            <button className="mt-5 mb-5 download-button a">
              <a
                href="/assets/Documents/demo_csv.csv"
                title="click to download"
                download
              >
                Download Demo CSV
              </a>
            </button>
          </div>
        </>
      );
    case FieldType.SuggestionButton:
      return (
        <>
          <Button
            className="mt-5 mb-5 download-button a"
            onChange={handleChange}
            variant="text"
            onClick={handleClick}
            onBlur={handleBlur}
            disabled={disabled}
          >
            <a>{label || sentenceCase(name)}</a>
          </Button>
        </>
      );
    case FieldType.SelectTime:
      return (
        <>
          <InputLabel htmlFor="filled-adornment-password" className="mt-5">
            {label || sentenceCase(name)}
          </InputLabel>
          <TextField
            key={name}
            margin="dense"
            name={name}
            id={id || name}
            type="time"
            value={value}
            fullWidth
            variant="standard"
            onChange={handleChange}
            inputProps={inputProps}
            onBlur={handleBlur}
            error={error && touched && error}
            helperText={error && touched && error}
            disabled={disabled}
          />
        </>
      );
    case FieldType.SelectFromButton:
      return (
        <>
          <Button
            className="mt-10 mb-5 mx-3"
            onChange={handleChange}
            variant="contained"
            onClick={handleClick}
            onBlur={handleBlur}
            disabled={disabled}
          >
            {label || sentenceCase(name)}
          </Button>
        </>
      );
    default:
      return <SFError>Unknown field</SFError>;
  }
}

function SFFormItem(props: TSFFormItem) {
  return (
    <>
      <RenderFormItem {...props}></RenderFormItem>
    </>
  );
}

export default SFFormItem;
