import { useEffect, useRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import _ from "@lodash";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { SuggestionFromChatGPT } from "../store/campaignSlice";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyAll } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import TextareaAutosize from "@mui/base/TextareaAutosize";

const schema = yup.object().shape({
  text: yup.string().required("Please Enter your text."),
});

const defaultValues = {
  text: "",
};

function MessageSuggestionModal() {
  const dispatch = useDispatch<any>();
  const [isLoading, setIsLoading] = useState(false);

  const SuggestedData = useSelector(
    (state: any) => state.campaign.suggstionText
  );

  const [text, setText] = useState<any>(SuggestedData);
  const [isCopied, setIsCopied] = useState<any>(false);
  const textAreaRef = useRef(null);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setText(SuggestedData);
    setIsLoading(false);
  }, [SuggestedData]);

  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = async (searchtext: any) => {
    dispatch(SuggestionFromChatGPT(searchtext));
    setIsLoading(true);
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={4} alignItems={"center"}>
          <form
            name="suggestiontextform"
            noValidate
            className="flex flex-col justify-center w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item md>
                <Controller
                  name="text"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      className="mb-16 mt-10"
                      label="Enter your requirement"
                      type="text"
                      error={!!errors.text}
                      helperText={errors?.text?.message}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item>
                {isLoading ? (
                  <LoadingButton
                    loading
                    variant="contained"
                    className="w-full mx-auto mt-16"
                  >
                    Send
                  </LoadingButton>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className="w-full mx-auto mt-16"
                    size="large"
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                    type="submit"
                  >
                    Send
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </Stack>
      </Stack>
      <hr />
      {text && (
        <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item md>
              <TextareaAutosize
                className="suggestionarea-custom"
                ref={textAreaRef}
                value={text}
              />
            </Grid>
            <Grid item>
              <CopyToClipboard text={text} onCopy={onCopyText}>
                <span>{isCopied ? "Copied!" : <CopyAll />}</span>
              </CopyToClipboard>
            </Grid>
          </Grid>
        </Stack>
      )}
    </>
  );
}

export default MessageSuggestionModal;
