import { AddCircle } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { motion } from 'framer-motion';

function SFFabAction({ onClick, icon = <AddCircle /> }) {
  return <>
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
    >
      <Fab color="primary" aria-label="add" sx={{
        position: 'absolute',
        bottom: 16,
        right: 16,
      }} onClick={onClick}>
        {icon}
      </Fab>
    </motion.div>
  </>
}

export default SFFabAction