import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";
import { fetchCampaignTagList } from "../../campaign/store/tagSlice";
import { EDialogType, closeDialog, openDialog } from "../../store/dialogSlice";

export type TNumber = {
  id: number;
  file: string;
  campaign_name: string;
  campaign_id: number;
};

export type TNumberState = {
  items: TNumber[];
  existingNumber: any;
  existingNumberList: any;
  insertedData: any;
  editing: number | boolean;
  deleting: number | boolean;
  filter: { [key: string]: number };
  export: boolean;
  sidebar: boolean;
};

export const createNumber = createAsyncThunk(
  "number/create",
  async (data: any, thunkAPI) => {
    const tag_id = data.campaign_id;
    const { dispatch } = thunkAPI;

    const formData: any = new FormData();
    formData.append("file", data.file);
    if (data.campaign_name && data.campaign_id === undefined) {
      formData.append("campaign_name", data.campaign_name);
    }
    if (data.campaign_id && data.campaign_name === undefined) {
      formData.append("campaign_id", data.campaign_id);
    }

    try {
      const response = await axios.post("/client-number/upload-file", formData);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      dispatch(fetchCampaignTagList({}));
      dispatch(fetchNumber({ tag_id: tag_id }));
      dispatch(closeDialog({ key: EDialogType.AddNewNumber }));
      dispatch(openDialog({ key: EDialogType.ExistingNumbers }));
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const fetchNumber = createAsyncThunk(
  "number/fetch",
  async (data: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { tag_id, filter } = data;

    const url = tag_id
      ? `/client-number?tag_id=${tag_id}`
      : `/client-number?tag_id=${tag_id}&number=${filter[0]?.value ?? ""}`;

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const deleteNumber = createAsyncThunk(
  "number/delete",
  async (id: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const response = await axios.delete(`/client-number/${id}`);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return { id };
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

const initialState: TNumberState = {
  items: [],
  existingNumber: null,
  existingNumberList: null,
  insertedData: null,
  editing: false,
  deleting: false,
  filter: {},
  export: false,
  sidebar: false,
};

const numberSlice = createSlice({
  name: "number",
  initialState,
  reducers: {
    startDeleting: (state, action) => {
      state.deleting = action.payload;
    },
    startEditing: (state, action) => {
      state.editing = action.payload;
    },
    stopEditing: (state) => {
      state.editing = false;
    },
    startExport: (state) => {
      state.export = true;
    },
    stopExport: (state) => {
      state.export = false;
    },
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
  },
  extraReducers: {
    [`${fetchNumber.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
    },
    [`${createNumber.fulfilled}`]: (state, action) => {
      state.existingNumber = action.payload.data.exist_number.length;
      state.existingNumberList = action.payload.data.exist_number;
      state.insertedData = action.payload.data.inserted_data;
    },
    [`${deleteNumber.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex((it) => it.id === action.payload.id);
      state.items.splice(index, 1);
    },
  },
});

export const {
  startDeleting,
  startEditing,
  stopEditing,
  startExport,
  stopExport,
  toggleSidebar,
} = numberSlice.actions;
export default numberSlice.reducer;
