import { Button, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "app/withAppProviders";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { closeDialog, EDialogType } from "../../store/dialogSlice";
import { deleteNumber } from "../store/numberSlice";

function NumberConfirmationModal() {
  const numberId = useSelector((state: any) => state.number.deleting);

  const number = useSelector((state: any) =>
    state.number.items.find((log) => log.id === state.number.deleting)
  );

  const [value] = useState<Moment | null>(moment());
  const dispatch = useAppDispatch();

  const deleteNumberHandler = async () => {
    dispatch(deleteNumber(numberId));
    dispatch(closeDialog({ key: EDialogType.NumberConfirmation }));
  };

  const closeDeleteHandler = () => {
    dispatch(closeDialog({ key: EDialogType.NumberConfirmation }));
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={4} alignItems={"center"}>
          <Typography variant="h6">
            Are you sure want to Delete {number.number}?
          </Typography>
        </Stack>
      </Stack>
      <hr />
      <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
        <Button type="submit" onClick={closeDeleteHandler} variant="contained">
          Cancel
        </Button>
        <Button type="submit" onClick={deleteNumberHandler} variant="contained">
          Yes
        </Button>
      </Stack>
    </>
  );
}

export default NumberConfirmationModal;
