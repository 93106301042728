import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import UserProfileHead from "./UserProfileHead";
import UpdateUserProfileForm from "./UpdateUserProfileForm";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
  },
  "& .FusePageSimple-content": {
    display: "flex",
  },
  "& .FusePageSimple-contentCard": {
    overflow: "hidden",
  },
}));

function UserProfile() {
  return (
    <Root
      header={<UserProfileHead />}
      content={<UpdateUserProfileForm />}
      innerScroll
    />
  );
}

export default UserProfile;
