import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import { Card, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useAppDispatch } from "app/withAppProviders";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useRef } from "react";
import WorklogChartByCategory from "../apps/pulse/worklogs/charts/WorklogChartByCategory";
import { fetchDashboardDetails } from "./store/dashboardSlice";
import { useNavigate } from "react-router-dom";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 160,
    height: 160,
    [theme.breakpoints.up("lg")]: {
      marginRight: 12,
      borderBottomRightRadius: 20,
    },
  },
  "& .FusePageSimple-toolbar": {
    minHeight: 56,
    height: 56,
    alignItems: "flex-end",
  },
  "& .FusePageSimple-rightSidebar": {
    width: 288,
    border: 0,
    padding: "12px 0",
  },
  "& .FusePageSimple-content": {},
}));

const container = {
  show: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

function DashboardApp(props) {
  const pageLayout = useRef(null);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchDashboardDetails({}));
  });

  const campaignActiveNumbers = useSelector(
    (state: any) => state.dashboard.activeNumber
  );
  const totalActiveNumbers = useSelector(
    (state: any) => state.dashboard.totalNumber
  );
  const totalTwilioNuber = useSelector(
    (state: any) => state.dashboard.twilio_numbers
  );

  return (
    <>
      <Root
        content={
          <div className="flex shrink-0 flex-col items-center">
            <Stack
              spacing={2}
              direction="row"
              alignItems={"center"}
              justifyContent={"center"}
              className="card-spacing"
            >
              <Card
                className="rounded-20 shadow mt-5 dashboard-card"
                onClick={() => {
                  navigate("/campaign");
                }}
              >
                <div className="p-20">
                  <Typography className="h3 font-medium">
                    Active Campaign
                  </Typography>
                  <Typography className="text-40 ml-42 justify-center font-semibold leading-none label-align">
                    {campaignActiveNumbers}
                  </Typography>
                </div>
              </Card>

              <Card
                className="rounded-20 shadow mt-5 dashboard-card"
                onClick={() => {
                  navigate("/numbers/list");
                }}
              >
                <div className="p-20">
                  <Typography className="h3 font-medium">Contacts</Typography>
                  <Typography className="text-40 ml-42 justify-center font-semibold leading-none label-align">
                    {totalActiveNumbers}
                  </Typography>
                </div>
              </Card>

              <Card
                className="rounded-20 shadow mt-5 dashboard-card"
                onClick={() => {
                  navigate("/twilio/numbers");
                }}
              >
                <div className="p-20">
                  <Typography className="h3 font-medium">
                    Twilio Numbers
                  </Typography>
                  <Typography className="text-40 ml-42 justify-center font-semibold leading-none label-align">
                    {totalTwilioNuber}
                  </Typography>
                </div>
              </Card>
            </Stack>

            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              className="shadow hover:shadow-md transition-shadow w-full text-left  md:text-24 mx-12 pt-16 pl-10 font-semibold rounded-6"
            >
              SMS sent in the past 7 days
            </Typography>

            <div className="widget flex w-full sm:w-1/2 lg:3/4 md:w-3/4 ">
              <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                className="flex flex-wrap w-full justify-center  "
              >
                <WorklogChartByCategory />
              </motion.div>
            </div>
          </div>
        }
        ref={pageLayout}
      />
    </>
  );
}

export default DashboardApp;
