import { useEffect } from "react";
import PeopleDialog from "./main/apps/people/admin/people/PeopleDialog";
import UserDetailDialog from "./main/apps/people/admin/user-detail/UserDetailDialog";
import WorklogDialog from "./main/apps/pulse/worklogs/WorklogDialog";
import CampaignDialog from "./main/apps/campaign/campaigns/CampaignDialog";
import NumberDialog from "./main/apps/number/numbers/NumberDialog";
import TwilioDialog from "./main/apps/twilio/twilioList/TwilioDialog";
import ConfirmationDialog from "./main/apps/campaign/campaigns/ConfirmationDialog";
import NumberConfirmationModal from "./main/apps/number/numbers/ConfirmationDialog";
import ChangePasswordModal from "./main/apps/user/userProfile/ChangePasswordDialog";
import TwilioNumberConfirmationModal from "./main/apps/twilio/twilioList/ConfirmationDialog";
import { EDialogType, registerDialog } from "./main/apps/store/dialogSlice";
import { useAppDispatch } from "./withAppProviders";
import VisibilityConfirmationModal from "./main/apps/twilio/twilioList/visibilityDialog";
import MessageSuggestionModal from "./main/apps/campaign/campaigns/MessageSuggestionDialog";
import ExistingNumberDialog from "./main/apps/number/numbers/ExistingNumberDialog";
import PurchaseDialog from "./main/apps/twilio/twilioList/PurchaseDialog";
import AddNewNumberDialog from "./main/apps/number/numbers/AddNewNumberDialog";

function Init({ children }) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      registerDialog({
        key: EDialogType.Worklog,
        open: false,
        title: "Users",
        desc: "User details",
      })
    );

    dispatch(
      registerDialog({
        key: EDialogType.Campaign,
        open: false,
        title: "Campaign",
        desc: "",
      })
    );
    dispatch(
      registerDialog({
        key: EDialogType.Number,
        open: false,
        title: "Add Contact To Existing List",
        desc: "",
      })
    );
    dispatch(
      registerDialog({
        key: EDialogType.UserProfile,
        open: false,
        title: "Change Password",
        desc: "",
      })
    );

    dispatch(
      registerDialog({
        key: EDialogType.Twilio,
        open: false,
        title: "Add Existing Twilio Number",
        desc: "",
      })
    );

    dispatch(
      registerDialog({
        key: EDialogType.People,
        open: false,
        title: "People",
        desc: "",
      })
    );

    dispatch(
      registerDialog({
        key: EDialogType.Confirmation,
        open: false,
        title: "Confirmation",
        desc: "",
      })
    );

    dispatch(
      registerDialog({
        key: EDialogType.NumberConfirmation,
        open: false,
        title: "Confirmation",
        desc: "",
      })
    );

    dispatch(
      registerDialog({
        key: EDialogType.TwilioNumberConfirmation,
        open: false,
        title: "Confirmation",
        desc: "",
      })
    );

    dispatch(
      registerDialog({
        key: EDialogType.VisibilityConfirmation,
        open: false,
        title: "Confirmation",
        desc: "",
      })
    );

    dispatch(
      registerDialog({
        key: EDialogType.PeopleUserDetail,
        open: false,
        title: "People / Details",
        desc: "",
      })
    );

    dispatch(
      registerDialog({
        key: EDialogType.MessageSuggestion,
        open: false,
        title: "Suggestion From ChatGPT",
        desc: "",
      })
    );

    dispatch(
      registerDialog({
        key: EDialogType.ExistingNumbers,
        open: false,
        title: "Invalid or Existing Numbers Failed To Upload",
        desc: "",
      })
    );

    dispatch(
      registerDialog({
        key: EDialogType.PurchaseConfirmation,
        open: false,
        title: "Confirm to Purchase Number",
        desc: "",
      })
    );

    dispatch(
      registerDialog({
        key: EDialogType.AddNewNumber,
        open: false,
        title: "Add New Contact List",
        desc: "",
      })
    );
  }, []);

  return (
    <>
      {children}
      <WorklogDialog />
      <PeopleDialog />
      <UserDetailDialog />
      <TwilioDialog />
      <CampaignDialog />
      <NumberDialog />
      <ConfirmationDialog />
      <NumberConfirmationModal />
      <ChangePasswordModal />
      <TwilioNumberConfirmationModal />
      <VisibilityConfirmationModal />
      <MessageSuggestionModal />
      <ExistingNumberDialog />
      <PurchaseDialog />
      <AddNewNumberDialog />
    </>
  );
}

export default Init;
