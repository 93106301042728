import FuseLoading from "@fuse/core/FuseLoading";
import withRouter from "@fuse/core/withRouter";
import { ColDef, ColumnState, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";
import { useAppDispatch } from "app/withAppProviders";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { EDialogType, openDialog } from "../../store/dialogSlice";
import { getFiltersByTable } from "../../store/selectors/tableFilterSelector";
import { ETable } from "../../store/tableFilterSlice";
import { fetchTwilioAvaliableNumbers } from "../store/twilioPurchaseSlice";
import { fetchTwilioSetting } from "../store/twilioSlice";

function TwilioPurchaseTable(props) {
  const dispatch = useAppDispatch();
  const rowData = useSelector((state: any) => state.twiliopurchase.items);
  const filter = useSelector((state) =>
    getFiltersByTable(state, { table: ETable.TwilioPurchaseNumbers })
  );

  const loader = useSelector((state: any) => state.twiliopurchase.loader);
  const gridRef = useRef<AgGridReact<any>>(null);

  useEffect(() => {
    dispatch(fetchTwilioSetting({}));
  }, []);

  useEffect(() => {
    dispatch(fetchTwilioAvaliableNumbers(filter));
  }, [dispatch, filter]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    { field: "phone_number", headerName: "Phone number" },
    {
      field: "phone_number",
      cellRenderer: ActionButtonRenderer([
        {
          click: (phone_number) => {
            dispatch(
              openDialog({
                key: EDialogType.PurchaseConfirmation,
                extras: phone_number,
              })
            );
          },
          title: "Purchase",
        },
      ]),
      headerName: "Actions",
    },
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    var defaultSortModel: ColumnState[] = [
      { colId: "date", sort: "desc", sortIndex: 0 },
      { colId: "id", sort: "desc", sortIndex: 1 },
    ];
    params.columnApi.applyColumnState({ state: defaultSortModel });

    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 130,
      columnLimits: [{ key: "task", minWidth: 300 }],
    });
  }, []);

  return (
    <>
      <div className="ag-theme-alpine w-full">
        {loader !== false ? (
          <FuseLoading />
        ) : (
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            multiSortKey={"ctrl"}
            sideBar={"filters"}
            enableCellTextSelection={true}
          ></AgGridReact>
        )}
      </div>
    </>
  );
}

TwilioPurchaseTable.propTypes = {};

export default withRouter(TwilioPurchaseTable);
