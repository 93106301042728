import FusePageSimple from '@fuse/core/FusePageSimple';
import { styled } from '@mui/material/styles';
import { useAppDispatch } from 'app/withAppProviders';
import { useEffect } from 'react';
import { ETable, registerFilter } from '../../../store/tableFilterSlice';
import { fetchPeople } from '../../store/peopleSlice';
import PeopleHead from './PeopleHead';
import PeopleTable from './PeopleTable';
import PeopleToolbar from './PeopleToolbar';

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    // [theme.breakpoints.up('sm')]: {
    //   minHeight: 136,
    //   height: 136,
    // },
  },
  '& .FusePageSimple-content': {
    display: 'flex',
  },
  '& .FusePageSimple-contentCard': {
    overflow: 'hidden',
  },
}));

function Peoples() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchPeople({}))
    dispatch(registerFilter({ key: ETable.People }))
  }, [dispatch])

  // const sidebar = useSelector((state: any) => state.people.sidebar);

  return <Root
    contentToolbar={<PeopleToolbar />}
    header={<PeopleHead />}
    content={<PeopleTable />}
    innerScroll />;
}

export default Peoples
