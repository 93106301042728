import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "app/withAppProviders";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ETable, registerFilter } from "../../store/tableFilterSlice";
import { fetchWorklog } from "../store/worklogSlice";
import WorklogHead from "./WorklogHead";
import WorklogTable from "./WorklogTable";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
    // [theme.breakpoints.up('sm')]: {
    //   minHeight: 136,
    //   height: 136,
    // },
  },
  "& .FusePageSimple-content": {
    display: "flex",
  },
  "& .FusePageSimple-contentCard": {
    overflow: "hidden",
  },
}));

function Worklogs() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchWorklog({}));
    dispatch(registerFilter({ key: ETable.Worklog }));
  }, [dispatch]);

  const sidebar = useSelector((state: any) => state.worklog.sidebar);

  // return <DateRangePicker />
  return (
    <Root
      // contentToolbar={<WorklogToolbar />}
      header={<WorklogHead />}
      content={<WorklogTable />}
      // rightSidebarContent={sidebar && <WorklogRightSidebar />}
      innerScroll
    />
  );
}

export default Worklogs;
