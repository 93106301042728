import { RouteConfig } from "../../../shared/types";
import AddCampaignForm from "./campaigns/AddCampaignForm";
import Campaigns from "./campaigns/Campaigns";

const CampaignRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "campaign",
      element: <Campaigns />,
      permissions: [""],
    },
    {
      path: "campaign/add",
      element: <AddCampaignForm />,
      permissions: [""],
    },
    {
      path: "campaign/edit",
      element: <AddCampaignForm />,
      permissions: [""],
    },
  ],
};

export default CampaignRouteConfig;
