import { Button, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "app/withAppProviders";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { closeDialog, EDialogType } from "../../store/dialogSlice";
import { deleteCampaign } from "../store/campaignSlice";

function ConfirmationModal() {
  const campaignId: any =
    useSelector((state: any) => state.campaign.deleting);

  const campaignName: any = useSelector((state: any) =>
    state.campaign.items.find((log) => log.id === state.campaign.deleting)
  );

  const [value] = useState<Moment | null>(moment());
  const dispatch = useAppDispatch();

  const deleteCampaignHandler = async () => {
    dispatch(deleteCampaign(campaignId));
    dispatch(closeDialog({ key: EDialogType.Confirmation }));
  };

  const closeDeleteHandler = () => {
    dispatch(closeDialog({ key: EDialogType.Confirmation }));
  }

  return <>
    <Stack direction='column' spacing={2}>
      <Stack direction='row' spacing={4} alignItems={"center"}>
        <Typography variant="h6">
          Are you sure want to Delete {campaignName?.campaign_name}?
        </Typography>
      </Stack>
    </Stack>
    <hr />
    <Stack direction='row' spacing={2} justifyContent={"flex-end"}>
      <Button type="submit" onClick={closeDeleteHandler} variant="contained">
        Cancel
      </Button>
      <Button type="submit" onClick={deleteCampaignHandler} variant="contained">
        Yes
      </Button>
    </Stack>
  </>;
}

export default ConfirmationModal;
