import { Typography } from "@mui/material";
import _ from "@lodash";
import { styled, ThemeProvider, useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import { memo } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { selectContrastMainTheme } from "app/store/fuse/settingsSlice";

const Root = styled("div")(({ theme }) => ({
  background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
  color: theme.palette.primary.contrastText,
}));

function Dashboard() {

  const theme = useTheme();

  const contrastTheme = useSelector(
    selectContrastMainTheme(theme.palette.primary.main)
  );

  return (
    <ThemeProvider theme={contrastTheme}>
      <Root>
        <div className="container relative p-16 sm:p-24 flex flex-col sm:flex-row justify-between items-center">
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <div className="flex flex-col items-center sm:items-start mb-16 sm:mb-0">
              <Typography className="h2 font-semibold" color="textPrimary">
                Visitors
              </Typography>
              <Typography className="h5 font-medium" color="textSecondary">
                Unique visitors by month
              </Typography>
            </div>
          </motion.div>
        </div>

        <div className="container relative h-200 sm:h-256 pb-16">
          <ReactApexChart />
        </div>
      </Root>
    </ThemeProvider>
  );
}

export default memo(Dashboard);
