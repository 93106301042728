/* eslint import/no-extraneous-dependencies: off */
import _ from "@lodash";
import { createSlice } from "@reduxjs/toolkit";
import settingsConfig from "app/fuse-configs/settingsConfig";
import jwtService from "app/services/jwtService";
import { setDefaultSettings } from "app/store/fuse/settingsSlice";
import "firebase/compat/auth";

export const setUserData = (user) => async (dispatch, getState) => {
  /*
  You can redirect the logged-in user to a specific route depending on his role
  */
  if (user.loginRedirectUrl) {
    settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
  }

  /*
  Set User Settings
  */
  dispatch(setDefaultSettings(user.settings));

  dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { data: { settings } });

  dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const updateUserShortcuts =
  (shortcuts) => async (dispatch, getState) => {
    const { user } = getState().auth;
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateUserData(newUser));

    return dispatch(setUserData(newUser));
  };

export const logoutUser = () => async (dispatch, getState) => {
  jwtService.logout();
  return dispatch(userLoggedOut(null));
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.permissions || user.permissions.length === 0) {
    // is guest
    return;
  }
  switch (user.from) {
  }
};

const initialState = {};

const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;

export const getUser = (state) => state.auth.user;
