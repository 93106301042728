import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import _ from "@lodash";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
});

const defaultValues = {
  password: "",
};

function VisibilityConfirmationModal() {
  const dispatch = useDispatch<any>();

  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmit(userInfo: any) {
    reset(defaultValues);
  }

  return (
    <>
      <form
        name="forgotpassordform"
        noValidate
        className="flex flex-col justify-center w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16 mt-7"
              label="Password"
              type="password"
              error={!!errors.password}
              helperText={errors?.password?.message}
              variant="outlined"
              required
              fullWidth
            />
          )}
        />
        <Button
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          type="submit"
        >
          Confirm
        </Button>
      </form>
    </>
  );
}

export default VisibilityConfirmationModal;
