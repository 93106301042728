import withRouter from "@fuse/core/withRouter";
import TableFilters from "app/shared/components/TableFilters";
import { ETableFilter } from "app/shared/components/TableFilters/TableFilters";
import { ETable } from "../../store/tableFilterSlice";

function NumberToolbar(props) {
  const filters = [
    {
      type: ETableFilter.SearchTextItemFilter,
      name: "number",
      label: "Search by name or number",
    },
  ];

  return (
    <div className="flex items-center justify-between">
      <TableFilters filters={filters} table={ETable.Number} />
    </div>
  );
}

export default withRouter(NumberToolbar);
