import { useEffect } from "react";
import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import SFForm, { TSFForm } from "app/shared/components/SFForm/SFForm";
import { FieldType } from "app/shared/components/SFForm/SFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import { motion } from "framer-motion";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  createCampaign,
  resetSuggestions,
  campaignMessageSuggestion,
  createCampaignStep2,
} from "../store/campaignSlice";
import CampaignUpdateHead from "./CampaignUpdateHead";
import { EDialogType, openDialog } from "../../store/dialogSlice";
import { fetchCampaignListingNumber } from "../../twilio/store/listingNumbersSlice";
import { fetchCampaignTagList } from "../store/tagSlice";
import Box from "@mui/material/Box";
import { fetchTwilio } from "../../twilio/store/twilioSlice";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import history from "@history";

const steps = ["Step 1", "Step 2"];

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
  },
  "& .FusePageSimple-content": {
    display: "flex",
  },
  "& .FusePageSimple-contentCard": {
    overflow: "hidden",
  },
}));

function AddCampaignForm() {
  // <----------------- stepper from ----------------->

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  // <----------------- stepper from ----------------->
  const campaign: any =
    useSelector((state: any) =>
      state.campaign?.items?.find((log) => log.id === state.campaign.editing)
    ) || {};

  const firstStepResponseData = useSelector(
    (state: any) => state?.campaign?.stepperItems
  );

  const [cronStartTime, setCronStartTime] = useState<string | undefined>(
    undefined
  );
  const [cronEndTime, setCronEndTime] = useState<string | undefined>(undefined);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTwilio([]));
    dispatch(fetchCampaignTagList({}));
    dispatch(fetchCampaignListingNumber([]));
    setCronTime();
  });

  const messageValidationHandler = (data: any) => {
    const textMessage = {
      text: data,
    };
    dispatch(campaignMessageSuggestion(textMessage));
  };

  const onSubmit = (w) => {
    w.id = campaign.id;
    if (activeStep === 0) {
      const data = dispatch(createCampaign(w));
      data.then((res) => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      });
    } else if (!campaign.id) {
      w.messageing_service_sid = firstStepResponseData.data;
      dispatch(createCampaignStep2(w));
    } else {
      history.push("/campaign");
    }
  };

  const setCronTime = () => {
    const startTime = new Date();
    const endTime = new Date();
    startTime.setUTCHours(
      campaign?.cron_time?.split(":")[0],
      campaign?.cron_time?.split(":")[1]
    );
    endTime.setUTCHours(
      campaign?.end_time?.split(":")[0],
      campaign?.end_time?.split(":")[1]
    );
    setCronStartTime(
      `${startTime.getHours().toString().padStart(2, "0")}:${startTime
        .getMinutes()
        .toString()
        .padStart(2, "0")}`
    );
    setCronEndTime(
      `${endTime.getHours().toString().padStart(2, "0")}:${endTime
        .getMinutes()
        .toString()
        .padStart(2, "0")}`
    );
  };

  const step1: TSFForm = {
    onSubmit,
    fields: [
      {
        name: "campaign_name",
        label: "Campaign Name",
        type: FieldType.Text,
        value: campaign.campaign_name || "",
        validation: Yup.string()
          .required("Campaign name is required")
          .typeError("Required!"),
      },

      {
        name: "brand_registration_sid",
        label: "Twilio Brand Id ",
        type: FieldType.Text,
        disabled: campaign.id ? true : false,
        isHelperTextShow: true,
        value: campaign.twilio_brand_id || "",
        validation: !campaign.id
          ? Yup.string().required("Twilio Brand Id is required")
          : "",
      },
    ],
    resetFormOnSubmit: false,
  };

  const step2: TSFForm = {
    onSubmit,
    fields: [
      {
        name: "sms_per_day",
        label: "SMS/Day",
        type: FieldType.Number,
        disabled: campaign.id ? true : false,
        value: campaign.sms_per_day || "",
        validation: !campaign.id
          ? Yup.number().required("SMS/Day is a required field")
          : "",
      },

      {
        name: "tag_id",
        label: "Select Name of the Contact List",
        type: FieldType.DynamicSelect,
        reduxObjectName: "tag",
        labelKey: "tag",
        disabled: campaign.id ? true : false,
        value: campaign.tag_id || "",
        validation: !campaign.id
          ? Yup.string().required(
              "Select Name of the Contact List is a required field"
            )
          : "",
      },

      {
        name: "twilio_number_id",
        label: "Sending Twilio Numbers",
        type: FieldType.MultiSelectDropdown,
        reduxObjectName: campaign.id ? "twilio" : "listingNumber",
        disabled: campaign.id ? true : false,
        value: campaign.id ? campaign.twilio_number_id : "",
        labelKey: "number",
        validation: !campaign.id
          ? Yup.array()
              .of(
                Yup.number().required(
                  "Sending Twilio Numbers is a required field"
                )
              )
              .required("Sending Twilio Numbers is a required field")
              .min(1, "Please select at least 1 sending twilio number")
          : "",
      },

      {
        type: FieldType.SuggestionButton,
        name: "Suggestion From ChatGPT",
        label: "Suggestion From ChatGPT",
        disabled: campaign.id ? true : false,
        handleClick: () => {
          dispatch(resetSuggestions());
          dispatch(openDialog({ key: EDialogType.MessageSuggestion }));
        },
      },

      {
        name: "message_samples",
        label: "Messages",
        type: FieldType.Array,
        isHelperTextShow: true,
        disabled: campaign.id ? true : false,
        handleBlur: (e: any) => {
          messageValidationHandler(e.target.value);
        },
        value: campaign.text || [
          {
            text: "",
          },
        ],
        validation: !campaign.id
          ? Yup.array().of(
              Yup.object().shape({
                text: Yup.string().required("Please enter your message"),
              })
            )
          : "",
        fields: [
          {
            name: "text",
            label: "Message",
            type: FieldType.LongText,
          },
        ],
      },

      {
        name: "cron_time",
        label: "Message Sending Start Time",
        type: FieldType.SelectTime,
        value: cronStartTime || campaign.cron_time || "",
        disabled: campaign.id ? true : false,
        validation: !campaign.id
          ? Yup.string()
              .required("Cron Time is a required field")
              .matches(
                /^([01]\d|2[0-3]):[0-5]\d$/,
                "Cron End Time must be a valid time format (HH:mm)"
              )
          : "",
      },

      {
        name: "end_time",
        label: "Message Sending End Time",
        type: FieldType.SelectTime,
        disabled: campaign.id ? true : false,
        value: cronEndTime || campaign.end_time || "",
      },

      {
        name: "description",
        label: "Description",
        type: FieldType.LongTextField,
        disabled: campaign.id ? true : false,
        value: campaign.description || "",
        validation: !campaign.id
          ? Yup.string().min(40).required("Description is a required field")
          : "",
      },

      {
        name: "message_flow",
        label: "Message Flow",
        disabled: campaign.id ? true : false,
        isHelperTextShow: true,
        type: FieldType.LongTextField,
        value: campaign.message_flow || "",
        validation: !campaign.id
          ? Yup.string().min(40).required("Message Flow is a required field")
          : "",
      },

      {
        name: "has_embedded_links",
        label: "Embedded Links",
        type: FieldType.RadioSelect,
        disabled: campaign.id ? true : false,
        value: campaign.has_embedded_links || "False",
        validation: !campaign.id
          ? Yup.string().required("Embedded Links is a required field")
          : "",
      },

      {
        name: "has_embedded_phone",
        label: "Embedded Phone",
        type: FieldType.RadioSelect,
        disabled: campaign.id ? true : false,
        value: campaign.has_embedded_phone || "False",
        validation: !campaign.id
          ? Yup.string().required("Embedded Phone is a required field")
          : "",
      },
    ],
    resetFormOnSubmit: false,
  };

  return (
    <Root
      header={<CampaignUpdateHead />}
      content={
        <>
          <div className="ml-10 mx-5 mt-5 mb-5 w-full">
            <Box className="stepper-box">
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>

            {activeStep === 0 && (
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <SFForm {...step1}></SFForm>
                </Grid>
              </Grid>
            )}

            {activeStep === 1 && (
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <SFForm {...step2}></SFForm>
                </Grid>
                <Grid item md={6}>
                  <Typography
                    component={motion.span}
                    className="hidden sm:flex text-10 md:text-18 mx-12 mt-5 font-semibold"
                  >
                    Notes :
                  </Typography>
                  <Typography
                    component={motion.span}
                    className="hidden sm:flex text-10 md:text-15 mx-12 mt-12"
                  >
                    Description : A short description of what this SMS campaign
                    does. Min length: 40 characters. Max length: 4096
                    characters.
                  </Typography>
                  <Typography
                    component={motion.span}
                    className="hidden sm:flex text-10 md:text-15 mx-12 mt-12"
                  >
                    Message Flow : Required for all Campaigns. Details around
                    how a consumer opts-in to their campaign, therefore giving
                    consent to receive their messages. If multiple opt-in
                    methods can be used for the same campaign, they must all be
                    listed. 40 character minimum. 2048 character maximum.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </div>
        </>
      }
      innerScroll
    />
  );
}

export default AddCampaignForm;
