import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "app/withAppProviders";
import { useEffect } from "react";
import { ETable, registerFilter } from "../../store/tableFilterSlice";
import { fetchCampaign } from "../store/campaignSlice";
import CampaignHead from "./CampaignHead";
import CampaignTable from "./CampaignTable";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
  },
  "& .FusePageSimple-content": {
    display: "flex",
  },
  "& .FusePageSimple-contentCard": {
    overflow: "hidden",
  },
}));

function Campaigns() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchCampaign({}));
    dispatch(registerFilter({ key: ETable.Campaign }));
  }, [dispatch]);

  return (
    <Root header={<CampaignHead />} content={<CampaignTable />} innerScroll />
  );
}

export default Campaigns;
