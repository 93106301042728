import SFForm, { TSFForm } from "app/shared/components/SFForm/SFForm";
import { FieldType } from "app/shared/components/SFForm/SFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { closeDialog, EDialogType } from "../../store/dialogSlice";
import { createWorklog } from "../store/worklogSlice";

function AddWorklogForm() {
  const worklog =
    useSelector((state: any) =>
      state.worklog.items.find((log) => log.id === state.worklog.editing)
    ) || {};
  const [value] = useState<Moment | null>(moment());
  const dispatch = useAppDispatch();
  const onSubmit = (w) => {
    w.id = worklog.id;
    dispatch(createWorklog(w));
    dispatch(closeDialog({ key: EDialogType.Worklog }));
  };

  const form: TSFForm = {
    onSubmit,
    fields: [
      {
        name: "first_name",
        type: FieldType.Text,
        value: worklog.first_name || "",
      },
      {
        name: "last_name",
        type: FieldType.Text,
        value: worklog.last_name || "",
      },
      {
        name: "email",
        type: FieldType.Text,
        value: worklog.email || "",
      },
    ],
    resetFormOnSubmit: true,
  };

  return <SFForm {...form}></SFForm>;
}

export default AddWorklogForm;
