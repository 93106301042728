import SFForm, { TSFForm } from "app/shared/components/SFForm/SFForm";
import { FieldType } from "app/shared/components/SFForm/SFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { closeDialog, EDialogType } from "../../store/dialogSlice";
import { createNumber } from "../store/numberSlice";

function AddNumberForm(props) {
  const campaign_id = props.extras;

  const number =
    useSelector((state: any) =>
      state.number.items.find((log) => log.id === state.number.editing)
    ) || {};

  const [value] = useState<Moment | null>(moment());
  const dispatch = useAppDispatch();

  const onSubmit = (uploadData: any) => {
    uploadData.campaign_id = campaign_id;
    dispatch(createNumber(uploadData));
    dispatch(closeDialog({ key: EDialogType.Number }));
  };

  const form: TSFForm = {
    onSubmit,
    fields: [
      {
        name: "fileUploadButton",
        label: "File Upload(csv)",
        type: FieldType.button,
      },
      {
        name: "file",
        label: "File Upload(csv)",
        type: FieldType.FileUpload,
        value: number.number || "",
        validation: Yup.string().required(),
      },
    ],
    resetFormOnSubmit: true,
  };

  return <SFForm {...form}></SFForm>;
}

export default AddNumberForm;
