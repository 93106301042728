import FuseUtils from "@fuse/utils/FuseUtils";
import axios from "axios";
import history from "@history";
import { showMessage } from "app/store/fuse/messageSlice";
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }
  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => response,
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            this.emit(
              "onUnauthorizedAccess",
              "Don't have Permission to access"
            );
          }

          if (
            err.response.status === 403 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an forbidden response, logout the user
            // const { data: { error } } = err.response;
            // this.emit('onAutoLogout', error);
            // this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  signInWithGoogle = (idToken: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post("/user/signin-with-google", {
          idToken,
        });
        const { token, user } = response.data;
        this.setSession(token);
        resolve(user);
      } catch (errors) {
        reject(errors);
      }
    });
  };

  signIn = (data: any) => {
    const { dispatch } = data;
    const formData: any = new FormData();
    formData.append("username", data.email);
    formData.append("password", data.password);
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post("/login", formData);
        dispatch(
          showMessage({ message: response.data.message, variant: "success" })
        );
        const {
          access_token,
          is_admin,
          is_campaign_added,
          is_number_added,
          is_purchase_number_added,
          is_twilio_details_added,
        } = response.data.data;
        this.setSession(access_token);
        sessionStorage.setItem("isadmin", is_admin);
        sessionStorage.setItem("campaignAdded", is_campaign_added);
        sessionStorage.setItem("numberAdded", is_number_added);
        sessionStorage.setItem("purchaseNumberAdded", is_purchase_number_added);
        sessionStorage.setItem("twilioAdded", is_twilio_details_added);

        const permissions = [] as string[]; // default to no permissions.
        if (is_admin) {
          // If admin provie permission to access admin routes.
          permissions.push("admin");
        }
        sessionStorage.setItem("access_token", access_token);
        resolve({ access_token, permissions });
      } catch (errors: any) {
        reject(errors);
        dispatch(
          showMessage({ message: errors.response.data.error, variant: "error" })
        );
      }
    });
  };

  signUp = (signupData: any) => {
    const data: any = {
      name: signupData.name,
      email: signupData.email,
      password: signupData.password,
    };
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post("/signup", data);
        this.setSession(response.data);
        history.push("/login");
        resolve(response.data.message);
      } catch (errors: any) {
        reject(errors.response.data.error);
      }
    });
  };

  signInWithToken = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get("/user/signin-with-token");
        const { user } = response.data;
        resolve(user);
      } catch (error) {
        reject(new Error("Failed to login with token."));
      }
    });
  };

  setSession = (token: string | null) => {
    if (token) {
      localStorage.setItem("token", token);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      localStorage.removeItem("token");
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null);
    localStorage.clear();
    sessionStorage.clear();
  };

  getAccessToken = () => {
    return window.localStorage.getItem("token");
  };

  handleAuthentication = () => {
    const accessToken = this.getAccessToken();

    if (accessToken) {
      this.setSession(accessToken);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
    }
  };
}

const instance = new JwtService();

export default instance;
