import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { EGender } from "../types";

export default function MemberCard({ member }) {
  const photo =
    member.photoUrl ||
    (member.gender === EGender.Female
      ? "https://s3.ap-south-1.amazonaws.com/portal-assets.seaflux.tech/people/photos/female.jpg"
      : "https://s3.ap-south-1.amazonaws.com/portal-assets.seaflux.tech/people/photos/male.jpg");

  return (
    <>
      <div className="widget flex w-1/2 sm:w-1/4 p-16">
        <Card sx={{ maxWidth: 200, textAlign: "center" }}>
          {/* <CardActionArea> */}
          <CardMedia
            component="img"
            height="200"
            image={photo}
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {member.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {member.displayDesignation}
            </Typography>
          </CardContent>
          {/* </CardActionArea> */}
        </Card>
      </div>
    </>
  );
}
