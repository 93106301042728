import App from "app/App";
import React from "react";
import { createRoot } from "react-dom/client";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./styles/app-base.css";
import "./styles/app-components.css";
import "./styles/app-utilities.css";
import "./styles/ag-grid-override.css";
import "./styles/common-component.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = createRoot(document.getElementById("root") as Element);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
