import { RouteConfig } from "../../../shared/types";
import NumberList from "./numbers/NumberList";
import Numbers from "./numbers/Numbers";

const NumberRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },

  routes: [
    {
      path: "number/:id",
      element: <Numbers />,
      permissions: [""],
    },
    {
      path: "numbers/list",
      element: <NumberList />,
      permissions: [""],
    },
  ],
};

export default NumberRouteConfig;
