import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { getUser, logoutUser } from "app/login/store/userSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

function UserMenu(props) {
  const dispatch = useDispatch();
  const user: any = useSelector(getUser);
  const userData = useSelector((state: any) => state.user.items);

  const currentTwilioBalance = useSelector((state: any) => state.dashboard.twilio_balance);

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <>
      <Typography component="span" className="font-semibold flex">
        My Twilio Balance : {currentTwilioBalance ? currentTwilioBalance : "0"}
      </Typography>

      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {userData?.name}
          </Typography>
          <Typography
            className="text-11 font-medium capitalize"
            color="textSecondary"
          >
            {user.userType}
          </Typography>
        </div>

        {userData ? (
          <Avatar
            className="md:mx-4"
            alt="user photo"
            src="assets/images/user.png"
          />
        ) : (
          <Avatar className="md:mx-4">{user.firstName}</Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {localStorage.getItem("token") ? (
          <>
            <MenuItem component={Link} to="/userprofile" role="button">
              <ListItemIcon className="min-w-40">
                <Icon>person</Icon>
              </ListItemIcon>
              <ListItemText
                primary="My Profile"
              />
            </MenuItem>
            <MenuItem
              onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
                dispatch(logoutUser() as any);
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </>
        ) : (
          <></>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
