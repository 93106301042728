import { createSlice } from "@reduxjs/toolkit";

export enum ETable {
  Worklog = "Worklog",
  Campaign = "Campaign",
  Twilio = "Twilio",
  TwilioPurchaseNumbers = "TwilioPurchaseNumbers",
  Number = "Number",
  NumberList = "NumberList",
  Project = "Project",
  People = "People",
  Logs = "Logs",
  PeopleUserDetail = "PeopleUserDetail",
}

type TFilter = {
  key: string;
  value: string | number;
};

type TTableFilter = {
  key: ETable;
  items: TFilter[];
};

type TTableFilterState = {
  items: TTableFilter[];
};

const initialState: TTableFilterState = {
  items: [],
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    registerFilter: (state, action) => {
      const filterTable = state.items.find((d) => d.key === action.payload.key);
      if (!filterTable) {
        state.items.push({
          key: action.payload.key,
          items: [],
        });
      }
    },
    addFilter: (state, action) => {
      const filterTable = state.items.find((d) => d.key === action.payload.key);

      if (filterTable) {
        const { items: filters = [] } = filterTable;
        const { filter: payloadFilter } = action.payload;

        const filter = filters.find((d) => d.key === payloadFilter.key);

        if (payloadFilter.value.length >= 2) {
          if (filter) {
            filter.value = payloadFilter.value;
          } else {
            filterTable.items.push({
              key: payloadFilter.key,
              value: payloadFilter.value,
            });
          }
        }

      }
    },
    removeFilter: (state, action) => {
      const filterTable = state.items.find((d) => d.key === action.payload.key);
      if (filterTable) {
        const { items: filters = [] } = filterTable;
        const { filter: payloadFilter } = action.payload;

        const filterIndex = filters.findIndex(
          (d) => d.key === payloadFilter.key
        );

        if (filterIndex >= 0) {
          filters.splice(filterIndex, 1);
        }
      }
    },
  },
});

export const { addFilter, removeFilter, registerFilter } = filterSlice.actions;
export default filterSlice.reducer;
