import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";

function SFAutocomplete(props) {
  const {
    error,
    name,
    label,
    id,
    touched,
    value: propVal,
    handleBlur,
    items,
    setFieldValue,
    labelKey = "name",
    disabled
  } = props;

  const [value, setValue] = useState(propVal);

  return (
    <Autocomplete
      getOptionLabel={(option: any) => option[labelKey] || ""}
      renderInput={(props) => (
        <TextField
          {...props}
          label={label || ""}
          error={error && touched && error}
          variant="standard"
        />
      )}
      key={name}
      id={id || name}
      fullWidth
      value={items.find((option) => option.tag_id === value)}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => {
        return option.id === value;
      }}
      onChange={(_, value) => {
        setFieldValue(name, value ? value.tag_id : value);
        setValue(value ? value.tag_id : value);
      }}
      onBlur={handleBlur}
      options={items}
    />
  );
}

export default SFAutocomplete;
