import { createSlice } from "@reduxjs/toolkit";
import { fetchUser } from "app/main/apps/user/store/userProfileSlice";
import jwtService from "app/services/jwtService";
import { setUserData } from "./userSlice";

export const loginUser =
  ({ data }) =>
  async (dispatch, state) => {
    const { email, password } = data;
    const user = await jwtService.signIn({ email, password, dispatch });
    await dispatch(setUserData(user));
    if (user) {
      dispatch(fetchUser({}));
    }
    return dispatch(loginSuccess(null));
  };

const initialState = {
  success: false,
  errors: [],
};

const loginSlice = createSlice({
  name: "auth/login",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.success = true;
      state.errors = [];
    },
    loginError: (state, action) => {
      state.success = false;
      state.errors = action.payload;
    },
  },
  extraReducers: {},
});

export const { loginSuccess, loginError } = loginSlice.actions;

export default loginSlice.reducer;
