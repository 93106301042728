import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";

export type ForgotPassword = {
  id: number;
  email: string;
  userInfo: string;
};

export type ForgotPasswordState = {
  items: ForgotPassword[];
};

export const forgotPasswordEmailVerify = createAsyncThunk(
  "/forgot-password",
  async (userInfo: ForgotPassword, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const { email } = userInfo;
    const body = {
      email,
    };

    try {
      const response = await axios.post("/forget-password", body);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const resetPassword = createAsyncThunk(
  "/reset-password",
  async (userInfo: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { password } = userInfo;
    const body = {
      password,
    };

    try {
      const response = await axios.post(
        `/reset-password/${userInfo.token}`,
        body
      );
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

const initialState: ForgotPasswordState = {
  items: [],
};

const forgotpasswordSlice = createSlice({
  name: "forgotpassword",
  initialState,
  reducers: {},
  extraReducers: {},
});

export const {} = forgotpasswordSlice.actions;

export default forgotpasswordSlice.reducer;
