import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";

export type Setting = {
  id: number;
};

export type SettingState = {
  items: any;
  sidebar: boolean;
};

export const sendCronTime = createAsyncThunk(
  "setting/create",
  async (setting: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const time = {
      time: setting,
    };

    try {
      const response = await axios.post("/settings/time", time);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const fetchTime = createAsyncThunk(
  "setting/fetch",
  async (getTime: any) => {
    const response = await axios.get("/settings/time");
    return response.data;
  }
);

export const deleteSetting = createAsyncThunk(
  "setting/delete",
  async (id: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const response = await axios.delete(`/client-setting/${id}`);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

const initialState: SettingState = {
  items: {},
  sidebar: false,
};

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
  },
  extraReducers: {
    [`${sendCronTime.fulfilled}`]: (state, action) => {
      state.items = action.payload;
    },
    [`${fetchTime.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
    },
    [`${deleteSetting.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex((it) => it.id === action.payload.id);
      state.items.splice(index, 1);
    },
  },
});

export const { toggleSidebar } = settingSlice.actions;
export default settingSlice.reducer;
