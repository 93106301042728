import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "app/withAppProviders";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ETable, registerFilter } from "../../store/tableFilterSlice";
import NumberHead from "./NumberHead";
import NumberTable from "./NumberTable";
import NumberToolbar from "./NumberToolbar";
import { useLocation } from "react-router-dom";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
  },
  "& .FusePageSimple-content": {
    display: "flex",
  },
  "& .FusePageSimple-contentCard": {
    overflow: "hidden",
  },
}));

function Numbers() {
  const location = useLocation();
  const Id = location.pathname.split("/")[2];
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(registerFilter({ key: ETable.Number }));
  }, [dispatch]);

  const sidebar = useSelector((state: any) => state.number.sidebar);

  return (
    <Root
      contentToolbar={<NumberToolbar />}
      header={<NumberHead />}
      content={<NumberTable />}
      innerScroll
    />
  );
}

export default Numbers;
