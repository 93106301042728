import withRouter from "@fuse/core/withRouter";
import { ColDef, ColumnState, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";
import SFFabAction from "app/shared/components/SFFabAction";
import { dateFilterParams, dateFormater } from "app/shared/helper";
import { useAppDispatch } from "app/withAppProviders";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { sentenceCase } from "sentence-case";
import { EDialogType, openDialog } from "../../../store/dialogSlice";
import { getFiltersByTable } from "../../../store/selectors/tableFilterSelector";
import { ETable } from "../../../store/tableFilterSlice";
import { fetchPeople, startEditing, stopEditing, stopExport } from "../../store/peopleSlice";
import { EPeopleSection } from "./UserDetail";

function UserDetailTable({ section }) {
  const dispatch = useAppDispatch()
  const rowData = useSelector((state: any) => state.people.items);
  const filter = useSelector(state => getFiltersByTable(state, { table: ETable.PeopleUserDetail }));
  const shouldExport = useSelector((state: any) => state.people.export);
  const gridRef = useRef<AgGridReact<any>>(null)
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([])

  useEffect(() => {
    dispatch(fetchPeople(filter))
  }, [dispatch, filter])

  useEffect(() => {
    if (gridRef && gridRef.current && shouldExport) {
      gridRef.current.api.exportDataAsCsv({
        fileName: 'UserDetail.csv',
        columnKeys: columnDefs.filter(col => col.field != 'id').map(col => `${col.field}`)
      });
      dispatch(stopExport())
    }
  }, [shouldExport])

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
    };
  }, []);

  useEffect(() => {
    let sectionFields: ColDef[] = []
    if (section === EPeopleSection.PersonalDetails) {
      sectionFields = [
        { field: 'userDetail.dob', headerName: "DOB", valueFormatter: params => dateFormater(params.value) },
        { field: 'userDetail.birthday', headerName: "Birthday", valueFormatter: params => dateFormater(params.value) },
        { field: 'userDetail.bloodGroup', headerName: "Blood Group" },
        { field: 'userDetail.fatherName', headerName: "Father's Name" },
        { field: 'userDetail.maritalStatus', headerName: "Marital Status" },
        { field: 'userDetail.marriageDate', headerName: "Marriage Date", valueFormatter: params => dateFormater(params.value) },
        { field: 'userDetail.spouseName', headerName: "Spouse Name" },
        { field: 'userDetail.nationality', headerName: "Nationality" },
        { field: 'userDetail.residentialStatus', headerName: "Residential Status" },
        { field: 'userDetail.placeOfBirth', headerName: "Place Of Birth" },
        { field: 'userDetail.countryOfOrigin', headerName: "Country Of Origin" },
        { field: 'userDetail.religion', headerName: "Religion" },
        { field: 'userDetail.internationalEmployee', headerName: "International Employee" },
        { field: 'userDetail.physicallyChallenged', headerName: "Physically Challenged" },
        { field: 'userDetail.personalEmail', headerName: "Personal Email" },
        { field: 'userDetail.emergencyContactName', headerName: "Emergency Contact Name" },
        { field: 'userDetail.emergencyContactMobile', headerName: "Emergency Contact Mobile" },

      ]
    }

    if (section === EPeopleSection.JoiningDetails) {
      sectionFields = [
        {
          field: 'userDetail.dateOfJoining', headerName: "Date of Joining", valueFormatter: params => dateFormater(params.value)
        },
        { field: 'userDetail.dateOfAssociation', headerName: "Date of Association", valueFormatter: params => dateFormater(params.value) },
        { field: 'userDetail.confirmationDate', headerName: "Confirmation Date", valueFormatter: params => dateFormater(params.value) },
        { field: 'userDetail.probationPeriod', headerName: "Probation Period" },
        { field: 'userDetail.nextAppraisalDate', headerName: "Next Appraisal Date", valueFormatter: params => dateFormater(params.value) },
        { field: 'userDetail.noticePeriod', headerName: "Notice Period" },
      ]
    }

    if (section === EPeopleSection.Identity) {
      sectionFields = [
        { field: 'userDetail.aadharCard', headerName: "Aadhar Card" },
        { field: 'userDetail.pan', headerName: "Pan" },
        { field: 'userDetail.passport', headerName: "Passport" }
      ]
    }

    if (section === EPeopleSection.BackgroundCheck) {
      sectionFields = [
        { field: 'userDetail.backgroundCheckStatus', headerName: "Background Check Status" },
        { field: 'userDetail.verificationDate', headerName: "Verification Date", valueFormatter: params => dateFormater(params.value) },
        { field: 'userDetail.agencyName', headerName: "Agency Name" },
        { field: 'userDetail.remark', headerName: "Remark" }
      ]
    }

    if (section === EPeopleSection.Misc) {
      sectionFields = [
        { field: 'userDetail.accessCard', headerName: "Access Card #" },
        { field: 'userDetail.generalRemark', headerName: "General Remark" },
        { field: 'userDetail.foodPreference', headerName: "Food Preference" },
      ]
    }

    if (section === EPeopleSection.BankDetails) {
      sectionFields = [
        { field: 'userDetail.bankName', headerName: "Bank Name" },
        { field: 'userDetail.bankBranch', headerName: "Bank Branch" },
        { field: 'userDetail.bankAccountNo', headerName: "Bank Account No" },
        { field: 'userDetail.ifscCode', headerName: "IFSC Code" },
        { field: 'userDetail.iban', headerName: "IBAN" },
        { field: 'userDetail.accountType', headerName: "Account Type" },
        { field: 'userDetail.paymentType', headerName: "Payment Type" },
        { field: 'userDetail.ddPayableAt', headerName: "DD Payable At" },
        { field: 'userDetail.nameAsPerBankRecords', headerName: "Name As Per Bank Records" },
        { field: 'userDetail.esiNumber', headerName: "ESI Number" },
        { field: 'userDetail.uan', headerName: "UAN" },
        { field: 'userDetail.pfNumber', headerName: "PF Number" },
        { field: 'userDetail.pfJoinDate', headerName: "PF Join Date" },
        { field: 'userDetail.familyPfNo', headerName: "Family PF No" },
        { field: 'userDetail.isExistingMemberOfEps', headerName: "Is existing member of EPS" },
        { field: 'userDetail.allowEpfExcessContribution', headerName: "Allow EPF excess contribution" },
        { field: 'userDetail.allowEpsExcessContribution', headerName: "Allow EPS excess contribution" },
        { field: 'userDetail.labourWelfareFund', headerName: "Labour Welfare Fund" },
      ]
    }

    if (section === EPeopleSection.Address) {
      sectionFields = [
        {
          field: 'userDetail.addresses', headerName: "Addresses", valueFormatter: (params) => {
            return JSON.stringify(params.value);
          }
        }
      ]
    }

    if (section === EPeopleSection.Education) {
      sectionFields = [
        {
          field: 'userDetail.education', headerName: "Education", valueFormatter: (params) => {
            return JSON.stringify(params.value);
          }
        }
      ]
    }

    const colDefs = [
      { field: 'uid', filter: true, pinned: "left" as 'left', sortable: true },
      { field: 'name', filter: true, pinned: "left" as 'left', sortable: true },
      ...sectionFields,
      { field: 'status', headerName: 'Employement Status', filter: true },
      { field: 'userType', filter: true },
      {
        field: 'id', cellRenderer: ActionButtonRenderer({
          click: (id, extra) => {
            dispatch(startEditing(id))
            dispatch(openDialog({ key: EDialogType.PeopleUserDetail, extras: { section }, title: `${extra.name} / ${sentenceCase(section)}` }))
          }, title: "Edit"
        }),
        pinned: "right" as 'right',
        headerName: "Actions"
      }
    ]

    setColumnDefs(colDefs);
  }, [section]);



  const onGridReady = useCallback((params: GridReadyEvent) => {
    var defaultSortModel: ColumnState[] = [
      { colId: 'uid', sort: 'desc', sortIndex: 1 },
    ];
    params.columnApi.applyColumnState({ state: defaultSortModel });

    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 130,
      columnLimits: [{ key: 'task', minWidth: 300 }],
    });
  }, []);

  const onFirstDataRendered = useCallback(() => {
    const statusFilterComponent = gridRef.current!.api.getFilterInstance('status');
    statusFilterComponent!.setModel({
      type: 'equals',
      filter: 'Active'
    });

    const userTypeFilterComponent = gridRef.current!.api.getFilterInstance('userType');
    userTypeFilterComponent!.setModel({
      type: 'equals',
      filter: 'Employee'
    });
    gridRef.current!.api.onFilterChanged();
  }, []);

  return <>
    <div className="ag-theme-alpine w-full">
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        multiSortKey={'ctrl'}
        sideBar={'filters'}
        enableCellTextSelection={true}
      >
      </AgGridReact>
    </div>
  </>
}

UserDetailTable.propTypes = {

};

export default withRouter(UserDetailTable);