import { RouteConfig } from "app/shared/types";
import { Navigate } from "react-router-dom";
// import WorklogGuidelines from "./worklog-guidelines/WorklogGuidelines";
import Worklogs from "./worklogs/Worklogs";

const PulseRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    // {
    //   path: "/cars",
    //   element: <Navigate to="campaign" replace />,
    //   permissions: ["worklog_read"],
    // },
    {
      path: "user",
      element: <Worklogs />,
      permissions: ["admin"],
    },
    // {
    //   path: "cars/guidelines",
    //   element: <WorklogGuidelines />,
    //   permissions: ["worklog_read"],
    // },
  ],
};

export default PulseRouteConfig;
