import { addFilter, removeFilter } from "app/main/apps/store/tableFilterSlice";
import { useAppDispatch } from "app/withAppProviders";
import moment from "moment";
import { DateRangePicker } from 'rsuite';
import { sentenceCase } from "sentence-case";

function DateRangeFilter({ filter, table }) {
  const dispatch = useAppDispatch()
  const { name, label } = filter
  const handleChange = (newValue) => {
    if (newValue) {
      const [st, en] = newValue;
      const rangeWitoutTz = [
        moment(st.toISOString()).utcOffset(0, true).startOf('day').format(),
        moment(en.toISOString()).utcOffset(0, true).endOf('day').format()
      ]
      dispatch(addFilter({
        key: table,
        filter: {
          key: name,
          value: rangeWitoutTz
        }
      }))
    } else {
      dispatch(removeFilter({ key: table, filter: { key: name } }))
    }
  }

  return <>
    <DateRangePicker format="yyyy-MM-dd" onChange={handleChange} size="lg" placeholder={label || sentenceCase(name)} />
  </>
}

export default DateRangeFilter