import EmailVerification from "./emailverification";
import ForgotPassword from "./forgotpassword";

const ForgotpasswordConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: [],
  routes: [
    {
      path: "forgot-password",
      element: <EmailVerification />,
      permissions: [""],
    },
    {
      path: "reset-password/:token",
      element: <ForgotPassword />,
      permissions: [""],
    }
  ],
};

export default ForgotpasswordConfig;
