import withRouter from "@fuse/core/withRouter";
import { ColDef, ColumnState, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useAppDispatch } from "app/withAppProviders";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getFiltersByTable } from "../../store/selectors/tableFilterSelector";
import { ETable } from "../../store/tableFilterSlice";
import { useLocation } from "react-router-dom";
import { fetchSuccessLogs, fetchFailedLogs } from "../store/logsSlice";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function LogListsTable(props) {
  const dispatch = useAppDispatch();
  const rowData = useSelector((state: any) => state.logs.items);
  const [data, setData] = useState(rowData);

  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onSuccesshandler = () => {
    dispatch(fetchSuccessLogs(Id));
  };

  const onFailedhandler = () => {
    dispatch(fetchFailedLogs(Id));
  };

  const location = useLocation();
  const Id = location.pathname.split("/")[2];

  const filter = useSelector((state) =>
    getFiltersByTable(state, { table: ETable.Logs })
  );

  const gridRef = useRef<AgGridReact<any>>(null);

  useEffect(() => {
    dispatch(fetchSuccessLogs(Id));
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 150,
      filter: true,
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["clear", "apply"],
      },
    },

    {
      field: "user_number",
      headerName: "User Number",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["clear", "apply"],
      },
    },

    {
      field: "message",
      headerName: "Message",
      filter: "agTextColumnFilter",
      filterParams: {
        buttons: ["clear", "apply"],
      },
    },
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    var defaultSortModel: ColumnState[] = [
      { colId: "date", sort: "desc", sortIndex: 0 },
      { colId: "id", sort: "desc", sortIndex: 1 },
    ];
    params.columnApi.applyColumnState({ state: defaultSortModel });

    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 130,
      columnLimits: [{ key: "task", minWidth: 300 }],
    });
  }, []);

  return (
    <>
      <div className="ag-theme-alpine w-full">
        <Box
          sx={{
            width: "100%",
            marginBottom: "2%",
            marginTop: "2%",
            bgcolor: "background.paper",
          }}
        >
          <Tabs value={value} onChange={handleChange} centered>
            <Tab
              label="Success Messages"
              onClick={() => {
                onSuccesshandler();
              }}
            />
            <Tab
              label="Failed Messages"
              onClick={() => {
                onFailedhandler();
              }}
            />
          </Tabs>
        </Box>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          multiSortKey={"ctrl"}
          sideBar={"filters"}
          enableCellTextSelection={true}
        ></AgGridReact>
      </div>
    </>
  );
}

LogListsTable.propTypes = {};

export default withRouter(LogListsTable);
