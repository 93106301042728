import { Icon, IconButton } from "@mui/material";
import { motion } from "framer-motion";

export enum ESFIconType {
  IconButton,
  Icon
}

export default function SFIcon({ icon, onClick = () => { }, title = '', type = ESFIconType.IconButton }) {

  return <>
    {type === ESFIconType.IconButton &&
      <IconButton onClick={onClick} title={title} size="medium">
        <Icon component={motion.span}
          initial={{ scale: 0 }}
          animate={{ scale: 1, transition: { delay: 0.2 } }}
          className="text-24 md:text-32">{icon}</Icon>
      </IconButton>
    }

    {type === ESFIconType.Icon &&
      <Icon component={motion.span}
        initial={{ scale: 0 }}
        animate={{ scale: 1, transition: { delay: 0.2 } }}
        className="fuse-list-item-icon text-16 shrink-0">{icon}</Icon>
    }
  </>

}