import { createSelector } from "@reduxjs/toolkit";

export const getTableFilters = state => state.tableFilter.items;
export const getTable = (state, { table }) => table;
export const getFilterKey = (state, { key }) => key;

export const getFiltersByTable = createSelector(
  [getTableFilters, getTable],
  (filters, table) => (filters.find(filter => filter.key === table) || {}).items
) as any;

export const getFilterByTableAndKey = createSelector(
  [getFiltersByTable, getFilterKey],
  (filters = [], key) => (filters.find(filter => filter.key === key) || {}).value
) as any;