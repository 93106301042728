import { Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { memo } from "react";

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
    textAlign: "center",
  },
})(Typography);

function FuseSplashScreen() {
  return (
    <div id="fuse-splash-screen">
      <div className="center">
        <div className="logo">
          <WhiteTextTypography variant="h4">Loading...</WhiteTextTypography>
        </div>
        <div className="spinner-wrapper">
          <div className="spinner">
            <div className="inner">
              <div className="gap" />
              <div className="left">
                <div className="half-circle" />
              </div>
              <div className="right">
                <div className="half-circle" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(FuseSplashScreen);
