import FuseUtils from "@fuse/utils/FuseUtils";
import history from "@history";
import AppContext from "app/AppContext";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { matchRoutes, useLocation } from "react-router-dom";

export default function FuseAuthorization(props) {
  const appContext = useContext(AppContext);
  const { routes } = appContext as any;
  const { children } = props;
  const location = useLocation();
  const [state, setState] = useState({
    accessGranted: false,
  });
  const isLoggedIn = useSelector(({ auth }: any) => !!auth.user.access_token);

  let userPermissions =
    useSelector(({ auth }: any) => auth.user.permissions) || [];

  useEffect(() => {
    const { pathname } = location;

    const publicPaths = [
      "/login",
      "/forgot-password",
      "/reset-password",
      "/signup",
      "/verification",
      "/terms-conditions",
    ];

    const isPublicPath = publicPaths.some((it) =>
      it.includes(pathname.substring(1, 5))
    );

    const Campaign: any = sessionStorage.getItem("campaignAdded");
    const Number: any = sessionStorage.getItem("numberAdded");
    const PurchaseNumber: any = sessionStorage.getItem("purchaseNumberAdded");
    const Twilio: any = sessionStorage.getItem("twilioAdded");

    if (isPublicPath && isLoggedIn) {
      if (Twilio === "false") {
        history.push("/twilio/settings");
      } else if (PurchaseNumber === "false") {
        history.push("/twilio/purchase");
      } else if (Number === "false") {
        history.push("/numbers/list");
      } else if (Campaign === "false") {
        history.push("/campaign");
      } else {
        history.push("/dashboard");
      }
    }

    if (!isPublicPath && !isLoggedIn) {
      setTimeout(() => history.push("/login"), 0);
    }

    const matchedRoutes = matchRoutes(routes, pathname);

    const matched: any = matchedRoutes ? matchedRoutes[0] : false;

    let accessGranted = false;
    if (matched.route.permissions || matched.route.permissions?.length === 0) {
      accessGranted = true;
    } else {
      accessGranted = matched
        ? FuseUtils.hasPermission(matched.route.permissions, userPermissions)
        : true;
    }

    setState({ accessGranted });
  }, [location.pathname, isLoggedIn]);

  return state.accessGranted ? <>{children}</> : null;
}
