import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment, { Moment } from 'moment';

export type TCategory = {
  id: number
  name: string
}

export type TCategoryState = {
  items: TCategory[];
  lastUpdated: Moment | null
}

export const fetchCategory = createAsyncThunk(
  'category/fetch',
  async () => {
    const response = await axios.get('/category');
    return response.data
  }
)

const initialState: TCategoryState = {
  items: [],
  lastUpdated: null
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: {
    [`${fetchCategory.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
      state.lastUpdated = moment()
    }
  }
});

export default categorySlice.reducer;
