import { Button, Stack, Typography } from "@mui/material";
import { useAppDispatch } from "app/withAppProviders";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { closeDialog, EDialogType } from "../../store/dialogSlice";
import { deleteTwilio } from "../store/twilioSlice";

function TwilioNumberConfirmationModal() {
  const twilionumberId = useSelector((state: any) => state.twilio.deleting);

  const twilio = useSelector((state: any) =>
    state.twilio.items.find((log) => log.id === state.twilio.deleting)
  );

  const [value] = useState<Moment | null>(moment());
  const dispatch = useAppDispatch();

  const deleteNumberHandler = async () => {
    dispatch(deleteTwilio(twilionumberId));
    dispatch(closeDialog({ key: EDialogType.TwilioNumberConfirmation }));
  };

  const closeDeleteHandler = () => {
    dispatch(closeDialog({ key: EDialogType.TwilioNumberConfirmation }));
  };

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={4} alignItems={"center"}>
          <Typography variant="h6">
            Are you sure you want to delete this {twilio.number}, If yes, it
            will pause all your current running campaigns associated with this
            number?
          </Typography>
        </Stack>
      </Stack>
      <hr />
      <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
        <Button type="submit" onClick={closeDeleteHandler} variant="contained">
          Cancel
        </Button>
        <Button type="submit" onClick={deleteNumberHandler} variant="contained">
          Yes
        </Button>
      </Stack>
    </>
  );
}

export default TwilioNumberConfirmationModal;
