import SFForm, { TSFForm } from "app/shared/components/SFForm/SFForm";
import { FieldType, TSFFormItem } from "app/shared/components/SFForm/SFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from 'yup';
import "yup-phone";
import { closeDialog, EDialogType } from "../../../store/dialogSlice";
import { updateUserDetails } from "../../store/peopleSlice";
import { EPeopleSection } from "./UserDetail";

function UserDetailForm({ extras }) {
  const { section } = extras || {};
  const [form, setForm] = useState<TSFForm | null>(null);
  const people = useSelector((state: any) => state.people.items.find(log => log.id === state.people.editing)) || {}
  const userDetail = people.userDetail || {};
  const reduxUseDetailsEnums = useSelector((state: any) => state.enum.userDetail) || {}
  const reduxCommonEnums = useSelector((state: any) => state.enum.common) || {}

  const dispatch = useAppDispatch();
  const onSubmit = (w) => {
    const userDetail = { ...w };
    dispatch(updateUserDetails({
      id: people.id,
      userDetail
    }))
    dispatch(closeDialog({ key: EDialogType.PeopleUserDetail }))
  }

  useEffect(() => {
    let fields: TSFFormItem[] = [];

    if (section === EPeopleSection.PersonalDetails) {
      fields = [
        {
          name: "dob",
          label: "DOB",
          type: FieldType.Date,
          value: userDetail.dob || null,
          validation: Yup.date().nullable().default(null)
        },
        {
          name: "birthday",
          type: FieldType.Date,
          value: userDetail.birthday || null,
          validation: Yup.date().nullable().default(null)
        },
        {
          name: "bloodGroup",
          type: FieldType.StaticSelect,
          value: userDetail.bloodGroup || '',
          options: reduxUseDetailsEnums.bloodGroup.map(it => ({ id: it, name: it })),
          validation: Yup.string()
        },
        {
          name: "fatherName",
          type: FieldType.Text,
          value: userDetail.fatherName || '',
          validation: Yup.string()
        },
        {
          name: "maritalStatus",
          type: FieldType.StaticSelect,
          value: userDetail.maritalStatus || '',
          options: reduxUseDetailsEnums.maritalStatus.map(it => ({ id: it, name: it })),
          validation: Yup.string()
        },
        {
          name: "marriageDate",
          type: FieldType.Date,
          value: userDetail.marriageDate || null,
          validation: Yup.date().nullable().default(null)
        },
        {
          name: "spouseName",
          type: FieldType.Text,
          value: userDetail.spouseName || '',
          validation: Yup.string()
        },
        {
          name: "nationality",
          type: FieldType.Text,
          value: userDetail.nationality || '',
          validation: Yup.string()
        },
        {
          name: "residentialStatus",
          type: FieldType.Text,
          value: userDetail.residentialStatus || '',
          validation: Yup.string()
        },
        {
          name: "placeOfBirth",
          type: FieldType.Text,
          value: userDetail.placeOfBirth || '',
          validation: Yup.string()
        },
        {
          name: "countryOfOrigin",
          type: FieldType.Text,
          value: userDetail.countryOfOrigin || '',
          validation: Yup.string()
        },
        {
          name: "religion",
          type: FieldType.Text,
          value: userDetail.religion || '',
          validation: Yup.string()
        },
        {
          name: "internationalEmployee",
          type: FieldType.StaticSelect,
          options: reduxCommonEnums.yesNo.map(it => ({ id: it, name: it })),
          value: userDetail.internationalEmployee || '',
          validation: Yup.string()
        },
        {
          name: "physicallyChallenged",
          type: FieldType.StaticSelect,
          options: reduxCommonEnums.yesNo.map(it => ({ id: it, name: it })),
          value: userDetail.physicallyChallenged || '',
          validation: Yup.string()
        },
        {
          name: "personalEmail",
          type: FieldType.Text,
          value: userDetail.personalEmail || '',
          validation: Yup.string().email()
        },
        {
          name: "emergencyContactName",
          type: FieldType.Text,
          value: userDetail.emergencyContactName || '',
          validation: Yup.string()
        },
        {
          name: "emergencyContactMobile",
          type: FieldType.Text,
          value: userDetail.emergencyContactMobile || '',
          validation: Yup.string()
        },
      ]
    }

    if (section === EPeopleSection.JoiningDetails) {
      fields = [
        {
          name: "dateOfJoining",
          label: "Date of Joining",
          type: FieldType.Date,
          value: userDetail.dateOfJoining || null,
          validation: Yup.date().nullable().default(null)
        },
        {
          name: "dateOfAssociation",
          label: "Date of Association",
          type: FieldType.Date,
          value: userDetail.dateOfAssociation || null,
          validation: Yup.date().nullable().default(null)
        },
        {
          name: "confirmationDate",
          type: FieldType.Date,
          value: userDetail.confirmationDate || null,
          validation: Yup.date().nullable().default(null)
        },
        {
          name: "probationPeriod",
          type: FieldType.Text,
          value: userDetail.probationPeriod || null,
          validation: Yup.string()
        },
        {
          name: "nextAppraisalDate",
          type: FieldType.Date,
          value: userDetail.nextAppraisalDate || null,
          validation: Yup.date().nullable().default(null)
        },
        {
          name: "noticePeriod",
          type: FieldType.Text,
          value: userDetail.noticePeriod || null,
          validation: Yup.string()
        }
      ]
    }

    if (section === EPeopleSection.Identity) {
      fields = [
        {
          name: "aadharCard",
          type: FieldType.Text,
          value: userDetail.aadharCard || null,
          validation: Yup.string()
        },
        {
          name: "pan",
          type: FieldType.Text,
          value: userDetail.pan || null,
          validation: Yup.string()
        },
        {
          name: "passport",
          type: FieldType.Text,
          value: userDetail.passport || null,
          validation: Yup.string()
        }
      ]
    }

    if (section === EPeopleSection.BackgroundCheck) {
      fields = [
        {
          name: "backgroundCheckStatus",
          type: FieldType.StaticSelect,
          options: reduxUseDetailsEnums.backgroundCheckStatus.map(it => ({ id: it, name: it })),
          value: userDetail.backgroundCheckStatus || null,
          validation: Yup.string()
        },
        {
          name: "verificationDate",
          type: FieldType.Date,
          value: userDetail.verificationDate || null,
          validation: Yup.date().nullable().default(null)
        },
        {
          name: "agencyName",
          type: FieldType.Text,
          value: userDetail.agencyName || null,
          validation: Yup.string()
        },
        {
          name: "remark",
          type: FieldType.Text,
          value: userDetail.remark || null,
          validation: Yup.string()
        }
      ]
    }

    if (section === EPeopleSection.Misc) {
      fields = [
        {
          name: "accessCard",
          type: FieldType.Text,
          value: userDetail.accessCard || '',
          validation: Yup.string()
        },
        {
          name: "generalRemark",
          type: FieldType.Text,
          value: userDetail.generalRemark || '',
          validation: Yup.string()
        },
        {
          name: "foodPreference",
          type: FieldType.StaticSelect,
          options: reduxUseDetailsEnums.foodPreference.map(it => ({ id: it, name: it })),
          value: userDetail.foodPreference || '',
          validation: Yup.string()
        }
      ]
    }

    if (section === EPeopleSection.Address) {
      fields = [
        {
          name: "addresses",
          type: FieldType.Array,
          value: userDetail.addresses || [],
          validation: Yup.array()
            .of(
              Yup.object().shape({
                type: Yup.string().required(),
                address: Yup.string().required(),
                city: Yup.string().required(),
                state: Yup.string().required(),
                country: Yup.string().required(),
                pincode: Yup.string().required(),
              })
            ),
          fields: [
            {
              name: "type",
              label: "Address Type",
              type: FieldType.StaticSelect,
              options: reduxUseDetailsEnums.addressType.map(it => ({ id: it, name: it })),
            },
            {
              name: "address",
              label: "Address",
              type: FieldType.Text,
            },
            {
              name: "city",
              label: "City",
              type: FieldType.Text,
            },
            {
              name: "state",
              label: "State",
              type: FieldType.Text,
            },
            {
              name: "country",
              label: "Country",
              type: FieldType.Text,
            },
            {
              name: "pincode",
              label: "Pincode",
              type: FieldType.Text,
            }

          ]
        }
      ]
    }

    if (section === EPeopleSection.Education) {
      fields = [
        {
          name: "education",
          type: FieldType.Array,
          value: userDetail.education || [],
          validation: Yup.array()
            .of(
              Yup.object().shape({
                qualification: Yup.string().required(),
                startDate: Yup.date().nullable().default(null),
                endDate: Yup.date().nullable().default(null),
                institute: Yup.string(),
                grade: Yup.string(),
                remarks: Yup.string(),
              })
            ),
          fields: [
            {
              name: "qualification",
              label: "Qualification",
              type: FieldType.Text
            },
            {
              name: "startDate",
              label: "Start Date",
              type: FieldType.Date,
              value: null // Just in use for Default value, actual value is being taken from array value
            },
            {
              name: "endDate",
              label: "End Date",
              type: FieldType.Date,
              value: null // Just in use for Default value, actual value is being taken from array value
            },
            {
              name: "institute",
              label: "Institute",
              type: FieldType.Text,
            },
            {
              name: "grade",
              label: "Grade",
              type: FieldType.Text,
            },
            {
              name: "remarks",
              label: "Remarks",
              type: FieldType.Text,
            }

          ]
        }
      ]
    }

    if (section === EPeopleSection.BankDetails) {
      fields = [
        {
          name: "bankName",
          type: FieldType.Text,
          value: userDetail.bankName || '',
          validation: Yup.string()
        },
        {
          name: "bankBranch",
          type: FieldType.Text,
          value: userDetail.bankBranch || '',
          validation: Yup.string()
        },
        {
          name: "bankAccountNo",
          type: FieldType.Text,
          value: userDetail.bankAccountNo || '',
          validation: Yup.string()
        },
        {
          name: "ifscCode",
          label: "IFSC Code",
          type: FieldType.Text,
          value: userDetail.ifscCode || '',
          validation: Yup.string()
        },
        {
          name: "iban",
          label: "IBAN",
          type: FieldType.Text,
          value: userDetail.iban || '',
          validation: Yup.string()
        },
        {
          name: "accountType",
          type: FieldType.Text,
          value: userDetail.accountType || '',
          validation: Yup.string()
        },
        {
          name: "paymentType",
          type: FieldType.Text,
          value: userDetail.paymentType || '',
          validation: Yup.string()
        },
        {
          name: "ddPayableAt",
          label: "DD Payable At",
          type: FieldType.Text,
          value: userDetail.ddPayableAt || '',
          validation: Yup.string()
        },
        {
          name: "nameAsPerBankRecords",
          type: FieldType.Text,
          value: userDetail.nameAsPerBankRecords || '',
          validation: Yup.string()
        },
        {
          name: "esiNumber",
          label: "ESI Number",
          type: FieldType.Text,
          value: userDetail.esiNumber || '',
          validation: Yup.string()
        },
        {
          name: "uan",
          label: "UAN",
          type: FieldType.Text,
          value: userDetail.uan || '',
          validation: Yup.string()
        },
        {
          name: "pfNumber",
          label: "PF Number",
          type: FieldType.Text,
          value: userDetail.pfNumber || '',
          validation: Yup.string()
        },
        {
          name: "pfJoinDate",
          label: "PF Join Date",
          type: FieldType.Date,
          value: userDetail.pfJoinDate || null,
          validation: Yup.date().nullable().default(null)
        },
        {
          name: "familyPfNo",
          label: "Family PF No",
          type: FieldType.Text,
          value: userDetail.familyPfNo || '',
          validation: Yup.string()
        },
        {
          name: "isExistingMemberOfEps",
          label: "Is existing member of EPS",
          type: FieldType.StaticSelect,
          options: reduxCommonEnums.yesNo.map(it => ({ id: it, name: it })),
          value: userDetail.isExistingMemberOfEps || '',
          validation: Yup.string()
        },
        {
          name: "allowEpfExcessContribution",
          label: "Allow EPF excess contribution",
          type: FieldType.StaticSelect,
          options: reduxCommonEnums.yesNo.map(it => ({ id: it, name: it })),
          value: userDetail.allowEpfExcessContribution || '',
          validation: Yup.string()
        },
        {
          name: "allowEpsExcessContribution",
          label: "Allow EPS excess contribution",
          type: FieldType.StaticSelect,
          options: reduxCommonEnums.yesNo.map(it => ({ id: it, name: it })),
          value: userDetail.allowEpsExcessContribution || '',
          validation: Yup.string()
        },
        {
          name: "labourWelfareFund",
          label: "Labour Welfare Fund",
          type: FieldType.StaticSelect,
          options: reduxCommonEnums.yesNo.map(it => ({ id: it, name: it })),
          value: userDetail.labourWelfareFund || '',
          validation: Yup.string()
        }
      ]
    }

    const form: TSFForm = {
      onSubmit,
      fields
    }

    setForm(form);
  }, [section])



  return (form && <SFForm {...form}></SFForm>)
}

export default UserDetailForm