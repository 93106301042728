import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "app/withAppProviders";
import { useEffect } from "react";
import { ETable, registerFilter } from "../../store/tableFilterSlice";
import NumberListHead from "./NumberListHead";
import NumberListTable from "./NumberListTable";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
  },
  "& .FusePageSimple-content": {
    display: "flex",
  },
  "& .FusePageSimple-contentCard": {
    overflow: "hidden",
  },
}));

function NumberList() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(registerFilter({ key: ETable.NumberList }));
  }, [dispatch]);

  return (
    <Root
      header={<NumberListHead />}
      content={<NumberListTable />}
      innerScroll
    />
  );
}

export default NumberList;
