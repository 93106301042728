import SFForm, { TSFForm } from "app/shared/components/SFForm/SFForm";
import { FieldType } from "app/shared/components/SFForm/SFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from 'yup';
import "yup-phone";
import { closeDialog, EDialogType } from "../../../store/dialogSlice";
import { createPeople } from "../../store/peopleSlice";

function PeopleForm() {
  const people = useSelector((state: any) => state.people.items.find(log => log.id === state.people.editing)) || {}
  const userTypes = useSelector((state: any) => state.enum.users.userType) || []
  const departments = useSelector((state: any) => state.enum.userDetail.department) || []
  const userStatus = useSelector((state: any) => state.enum.users.status) || []
  const gender = useSelector((state: any) => state.enum.users.gender) || []

  const dispatch = useAppDispatch();
  const onSubmit = (w) => {
    w.id = people.id;
    dispatch(createPeople(w))
    dispatch(closeDialog({ key: EDialogType.People }))
  }

  const form: TSFForm = {
    onSubmit,
    fields: [
      {
        name: "firstName",
        type: FieldType.Text,
        value: people.firstName || '',
        validation: Yup.string().required()
      },
      // {
      //     name: "photo",
      //     type: FieldType.FileUpload,
      //     value: people.photo || '',
      //     validation: Yup.string().required()
      // },
      {
        name: "middleName",
        type: FieldType.Text,
        value: people.middleName || '',
        validation: Yup.string()
      },
      {
        name: "lastName",
        type: FieldType.Text,
        value: people.lastName || '',
        validation: Yup.string().required()
      },
      {
        name: "gender",
        value: people.gender || '',
        options: gender.map(it => ({ id: it, name: it })),
        type: FieldType.StaticSelect,
        validation: Yup.string().required()
      },
      {
        name: "email",
        type: FieldType.Text,
        value: people.email || '',
        validation: Yup.string().required().email()
      },
      {
        name: "mobile",
        type: FieldType.Text,
        value: people.mobile || '',
        validation: Yup.string().phone("IN").required()
      },
      {
        name: "displayDesignation",
        type: FieldType.Text,
        label: "Designation",
        value: people.displayDesignation || '',
        validation: Yup.string().required()
      },
      {
        name: "department",
        type: FieldType.StaticSelect,
        label: "Department",
        options: departments.map(it => ({ id: it, name: it })),
        value: people.department || '',
        validation: Yup.string().required()
      },
      {
        name: "reportingTo",
        label: "Reporting To",
        value: people.reportingTo || '',
        reduxObjectName: "people",
        type: FieldType.DynamicSelect,
        validation: Yup.string().required()
      },
      {
        name: "userType",
        value: people.userType || 'Employee',
        options: userTypes.map(it => ({ id: it, name: it })),
        type: FieldType.StaticSelect,
        validation: Yup.string().required()
      },
      {
        name: "status",
        value: people.status || 'Active',
        options: userStatus.map(it => ({ id: it, name: it })),
        type: FieldType.StaticSelect
      }
    ]
  }

  return <SFForm {...form}></SFForm>
}

export default PeopleForm