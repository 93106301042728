import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import withRouter from "@fuse/core/withRouter";
import { ColDef, ColumnState, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";
import SFFabAction from "app/shared/components/SFFabAction";
import { useAppDispatch } from "app/withAppProviders";
import { useSelector } from "react-redux";
import { EDialogType, openDialog } from "../../store/dialogSlice";
import { getFiltersByTable } from "../../store/selectors/tableFilterSelector";
import { ETable } from "../../store/tableFilterSlice";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  fetchCampaign,
  startEditing,
  startCampaign,
  stopEditing,
  stopExport,
  pauseCampaign,
  startDeleting,
} from "../store/campaignSlice";
import { fetchCampaignTagList } from "../store/tagSlice";

function CampaignTable(props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const rowData = useSelector((state: any) => state.campaign.items);

  const filter = useSelector((state) =>
    getFiltersByTable(state, { table: ETable.Campaign })
  );

  const shouldExport = useSelector((state: any) => state.campaign.export);
  const gridRef = useRef<AgGridReact<any>>(null);

  useEffect(() => {
    dispatch(fetchCampaign(filter));
    dispatch(fetchCampaignTagList({}));
  }, [dispatch, filter]);

  useEffect(() => {
    if (gridRef && gridRef.current && shouldExport) {
      gridRef.current.api.exportDataAsCsv({
        fileName: "Campaign.csv",
        columnKeys: columnDefs
          .filter((col) => col.field !== "id")
          .map((col) => `${col.field}`),
      });
      dispatch(stopExport());
    }
  }, [shouldExport]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<any[]>([
    { headerName: "S.No", valueGetter: "node.rowIndex + 1" },
    { field: "id" },
    { field: "campaign_name", headerName: "Campaign name" },
    { field: "sms_per_day", headerName: "Sms per day" },
    { field: "create_date", headerName: "Create Date" },

    {
      field: "id",
      cellRenderer: ((data) => {
        const actionButtons = [
          {
            click: (id, { status }) => {
              if (status === "paused" || status === "pending") {
                dispatch(startCampaign(id));
              } else if (status === "running") {
                dispatch(pauseCampaign(id));
              }
            },
            title: "CampaignStartStop",
          },
        ];
        return ActionButtonRenderer(actionButtons);
      })(),
      headerName: "Status",
    },

    {
      field: "id",

      cellRenderer: ActionButtonRenderer([
        {
          click: (id) => {
            dispatch(startEditing(id));
            navigate("/campaign/edit");
          },
          title: "Edit",
        },
        {
          click: (id) => {
            dispatch(startDeleting(id));
            dispatch(openDialog({ key: EDialogType.Confirmation }));
          },
          title: "Delete",
        },
        {
          click: (id) => {
            dispatch(startEditing(id));
            navigate(`/logs/${id}`);
          },
          title: "Lists",
        },
      ]),
      headerName: "Actions",
    },
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    var defaultSortModel: ColumnState[] = [
      { colId: "date", sort: "desc", sortIndex: 0 },
      { colId: "id", sort: "desc", sortIndex: 1 },
    ];
    params.columnApi.applyColumnState({ state: defaultSortModel });

    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 130,
      columnLimits: [{ key: "task", minWidth: 300 }],
    });
  }, []);

  return (
    <>
      <div className="ag-theme-alpine w-full">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          multiSortKey={"ctrl"}
          sideBar={"filters"}
          enableCellTextSelection={true}
        ></AgGridReact>
      </div>
      <SFFabAction
        onClick={() => {
          dispatch(stopEditing());
          navigate("/campaign/add");
        }}
      ></SFFabAction>
    </>
  );
}

CampaignTable.propTypes = {};

export default withRouter(CampaignTable);
