import withRouter from '@fuse/core/withRouter';
import SFIcon from 'app/shared/components/SFIcon';
import TableFilters from 'app/shared/components/TableFilters';
import { ETableFilter } from 'app/shared/components/TableFilters/TableFilters';
import { useAppDispatch } from 'app/withAppProviders';
import { ETable } from '../../../store/tableFilterSlice';
import { startExport } from '../../store/peopleSlice';

function UserDetailToolbar(props) {
    const dispatch = useAppDispatch();

    return (
        <div className='flex w-full items-center justify-end'>
            <SFIcon onClick={() => {
                dispatch(startExport())
            }} title="Export" icon="get_app" />
        </div>
    );
}

export default withRouter(UserDetailToolbar);