import SFForm, { TSFForm } from "app/shared/components/SFForm/SFForm";
import { FieldType } from "app/shared/components/SFForm/SFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { addAccount, fetchTwilioSetting } from "../store/twilioSlice";
import * as Yup from "yup";
import history from "@history";
import { Button, Stack, Typography } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { ArrowBack } from "@mui/icons-material";

function AddTwilioSettings() {
  const dispatch = useAppDispatch();
  const twilio = useSelector((state: any) => state.twilio.keys) || {};

  const [editing, setEditing] = useState(false);

  const [iSTwilioSidShow, setIsTwilioSidShow] = useState(false);
  const [iSTwilioTokenShow, setISTwilioTokenShow] = useState(false);

  const [twilioSidShow, setTwilioSidShow] = useState(false);
  const twilioSidShowHandler = () => {
    setTwilioSidShow(true);
  };

  const [twilioTokenShow, setTwilioTokenShow] = useState(false);
  const twilioAuthTokenShowHandler = () => {
    setTwilioTokenShow(true);
  };

  useEffect(() => {
    dispatch(fetchTwilioSetting({}));
  }, [dispatch]);

  const onSubmit = (w: any) => {
    setEditing(false);
    dispatch(addAccount(w)).then((res) => {
      const userDetails = { ...res.payload.data };
      if (userDetails.is_purchase_number_added === false) {
        history.push("twilio/purchase");
      } else if (userDetails.is_number_added === false) {
        history.push("/numbers/list");
      } else if (userDetails.is_campaign_added === false) {
        history.push("/campaign");
      }
    });
  };

  const form: TSFForm = {
    onSubmit,
    fields: [
      {
        name: "twilio_account_sid",
        label: "Twilio Account SID",
        type: FieldType.FilledInput,
        value: twilio.twilio_account_sid,
        validation: Yup.string()
          .required("this field is required")
          .typeError("Required!"),
      },
      {
        name: "twilio_auth_token",
        label: "Twilio Auth Token",
        type: FieldType.FilledInput,
        value: twilio.twilio_auth_token,
        validation: Yup.string()
          .required("this field is required")
          .typeError("Required!"),
      },
    ],
    resetFormOnSubmit: true,
  };

  return (
    twilio && (
      <>
        {!editing && (
          <div className="col-8 mx-5 mt-5 twilio-editing-field">
            <Stack direction="column" spacing={2}>
              <Stack
                direction="row"
                spacing={4}
                alignItems={"center"}
                justifyContent={"flex-start"}
              >
                <Typography variant="h6" component="h2">
                  <a
                    href="https://support.twilio.com/hc/en-us/articles/223136027-Auth-Tokens-and-How-to-Change-Them"
                    target="_blank"
                  >
                    Click here
                  </a>
                  &nbsp;on how to find the Auth Token in the Account Info
                  <br /> pane of the Console Dashboard page.
                </Typography>
              </Stack>
              <Stack direction="row" spacing={4} alignItems={"center"}>
                <Typography variant="h6" component="h2">
                  Twilio Account SID
                </Typography>
                <Typography variant="body1" component="h2">
                  {twilioSidShow && iSTwilioSidShow ? (
                    <div>{twilio.twilio_account_sid}</div>
                  ) : (
                    <div>************************************</div>
                  )}
                </Typography>
                <Typography variant="body1" component="h2">
                  <Button
                    size="medium"
                    onClick={() => {
                      setIsTwilioSidShow(!iSTwilioSidShow);
                      twilioSidShowHandler();
                    }}
                  >
                    {iSTwilioSidShow ? <Visibility /> : <VisibilityOffIcon />}
                  </Button>
                </Typography>
              </Stack>
              <Stack direction="row" spacing={4} alignItems={"center"}>
                <Typography variant="h6" component="h2">
                  Twilio Auth Token
                </Typography>
                <Typography variant="body1" component="h2">
                  {twilioTokenShow && iSTwilioTokenShow ? (
                    <div>{twilio.twilio_auth_token}</div>
                  ) : (
                    <div>************************************</div>
                  )}
                </Typography>
                <Typography variant="body1" component="h2">
                  <Button
                    size="medium"
                    onClick={() => {
                      setISTwilioTokenShow(!iSTwilioTokenShow);
                      twilioAuthTokenShowHandler();
                    }}
                  >
                    {iSTwilioTokenShow ? <Visibility /> : <VisibilityOffIcon />}
                  </Button>
                </Typography>
              </Stack>
              <Button
                onClick={() => {
                  setEditing(true);
                }}
                variant="contained"
              >
                Edit
              </Button>
            </Stack>
          </div>
        )}
        {editing && (
          <>
            <div>
              <ArrowBack
                fontSize="large"
                className="mt-5 mx-5"
                onClick={() => {
                  setEditing(!editing);
                }}
              />
            </div>

            <div className="col-8 mx-5 mt-5 twilio-edited">
              <Stack direction="column" spacing={2} className="twilio-stack">
                <Stack
                  direction="row"
                  spacing={4}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <Typography variant="h6" component="h2">
                    <a
                      href="https://support.twilio.com/hc/en-us/articles/223136027-Auth-Tokens-and-How-to-Change-Them"
                      target="_blank"
                    >
                      Click here
                    </a>
                    &nbsp;on how to find the Auth Token in the Account Info pane
                    of the Console Dashboard page.
                  </Typography>
                </Stack>
              </Stack>
              <SFForm {...form}></SFForm>
            </div>
          </>
        )}
      </>
    )
  );
}

export default AddTwilioSettings;
