import VerificationPage from "./VerificationPage";

const VerificationTable = () => {
  return (
    <>
      <VerificationPage />
    </>
  );
};

export default VerificationTable;
