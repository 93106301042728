import { Navigate } from "react-router-dom";
import FuseUtils from "@fuse/utils";
import FuseLoading from "@fuse/core/FuseLoading";
import Error404Page from "app/main/404/Error404Page";
import PulseRouteConfig from "app/main/apps/pulse/PulseRouteConfig";
import TwilioRouteConfig from "app/main/apps/twilio/TwilioRouteConfig";
import DashboardRouteConfig from "app/main/dashboard/DashboardRouteConfig";
import ExampleConfig from "app/main/example/ExampleConfig";
import LoginConfig from "app/login/LoginConfig";
import PeopleRouteConfig from "app/main/apps/people/PeopleRouteConfig";
import NumberRouteConfig from "app/main/apps/number/NumberRouteConfig";
import CampaignRouteConfig from "app/main/apps/campaign/CampaignRouteConfig";
import { Route } from "app/shared/types";
import SettingRouteConfig from "app/main/apps/setting/SettingRouteConfig";
import ForgotpasswordConfig from "app/forgotpassword/forgotpasswordConfig";
import VerificationRouteConfig from "app/main/apps/verification/VerificationRouteConfig";
import userProfileRouteConfig from "app/main/apps/user/UserProfileRouteConfig";
import LogsRouteConfig from "app/main/apps/logs/LogsRouteConfig";
import SubscriptionRouteConfig from "app/main/apps/Subscription/SubscriptionRouteConfig";

const routeConfigs = [
  PulseRouteConfig,
  PeopleRouteConfig,
  DashboardRouteConfig,
  ExampleConfig,
  LoginConfig,
  TwilioRouteConfig,
  NumberRouteConfig,
  CampaignRouteConfig,
  SettingRouteConfig,
  ForgotpasswordConfig,
  VerificationRouteConfig,
  userProfileRouteConfig,
  LogsRouteConfig,
  SubscriptionRouteConfig,
];

const routes: Route[] = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null),
  {
    path: "/",
    element: <Navigate to="login" />,
  },
  {
    path: "loading",
    element: <FuseLoading />,
  },
  {
    path: "404",
    element: <Error404Page />,
  },
  {
    path: "*",
    element: <Navigate to="404" />,
  },
];

export default routes;
