import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Moment } from "moment";

export type TWorklog = {
  id: number;
  projectId: number;
  categoryId: number;
  date: Moment;
  task: string;
  hours: number;
};

export type TWorklogState = {
  items: TWorklog[];
  editing: number | boolean;
  filter: { [key: string]: number };
  export: boolean;
  sidebar: boolean;
};

export const createWorklog = createAsyncThunk(
  "worklog/create",
  async (worklog: TWorklog) => {
    const response = await axios.post(`/worklog/${worklog.id || ""}`, worklog);
    return response.data;
  }
);

export const fetchWorklog = createAsyncThunk(
  "worklog/fetch",
  async (filters: any) => {
    const filter = filters.reduce((pv, cv) => {
      pv[cv.key] = cv.value;
      return pv;
    }, {});
    const response = await axios.get("/users?page=1", {
      params: {
        filter,
      },
    });
    return response.data;
  }
);

export const deleteWorklog = createAsyncThunk(
  "worklog/delete",
  async (id: number) => {
    const response = await axios.delete(`/worklog/${id}`);
    return response.data;
  }
);

const initialState: TWorklogState = {
  items: [],
  editing: false,
  filter: {},
  export: false,
  sidebar: false,
};

const worklogSlice = createSlice({
  name: "worklog",
  initialState,
  reducers: {
    startEditing: (state, action) => {
      state.editing = action.payload;
    },
    stopEditing: (state) => {
      state.editing = false;
    },
    startExport: (state) => {
      state.export = true;
    },
    stopExport: (state) => {
      state.export = false;
    },
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
  },
  extraReducers: {
    [`${createWorklog.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex((it) => it.id === action.payload.id);
      if (index >= 0) {
        state.items[index] = action.payload;
      } else {
        state.items.push(action.payload);
      }
    },
    [`${fetchWorklog.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
    },
    [`${deleteWorklog.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex((it) => it.id === action.payload.id);
      state.items.splice(index, 1);
    },
  },
});

export const {
  startEditing,
  stopEditing,
  startExport,
  stopExport,
  toggleSidebar,
} = worklogSlice.actions;
export default worklogSlice.reducer;
