import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export type TwilioCountryCode = {
  id: number;
  code: string;
};

export type TwilioCountryCodeState = {
  items: TwilioCountryCode[];
};

export const fetchCountryCode = createAsyncThunk(
  "countryCode/fetch",
  async () => {
    const response = await axios.post("/twilio/country-list");
    return response.data;
  }
);

const initialState: TwilioCountryCodeState = {
  items: [],
};

const twiliocountrycodeSlice = createSlice({
  name: "countrycode",
  initialState,
  reducers: {},
  extraReducers: {
    [`${fetchCountryCode.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
    },
  },
});

export const {} = twiliocountrycodeSlice.actions;
export default twiliocountrycodeSlice.reducer;
