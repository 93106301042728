import TableFilterItem from "./TableFilterItem";

export enum ETableFilter {
  ReduxDropdown,
  DateRange,
  SearchItem,
  SearchTextItemFilter,
  SearchCheckItemFilter,
  ReduxDropdownMultiSelect
}

function TableFilters({ filters, table }) {
  return <>
    <div className="flex flex-3 items-center space-x-10 overflow-hidden sm:px-16 table-filter">
      {filters.map(filter => <TableFilterItem filter={filter} table={table} key={filter.name} />)}
    </div>
  </>
}

export default TableFilters;