import DateRangeFilter from "./DateRangeFilter";
import ReduxDropdown from "./ReduxDropdown";
import ReduxDropdownMultiSelect from "./ReduxDropdownMultiSelect";
import SearchCheckItemFilter from "./TableCheckItem";
import { ETableFilter } from "./TableFilters";
import SearchItemfilter from "./TableSearchItem";
import SearchTextItemFilter from "./TableSearchTextItem";

function TableFilterItem({ filter, table }) {
  const { type } = filter;

  switch (type) {
    case ETableFilter.ReduxDropdown:
      return <ReduxDropdown filter={filter} table={table} />
    case ETableFilter.DateRange:
      return <DateRangeFilter filter={filter} table={table} />
    case ETableFilter.SearchItem:
      return <SearchItemfilter filter={filter} table={table} />
    case ETableFilter.SearchTextItemFilter:
      return <SearchTextItemFilter filter={filter} table={table} />
    case ETableFilter.SearchCheckItemFilter:
      return <SearchCheckItemFilter filter={filter} table={table} />
    case ETableFilter.ReduxDropdownMultiSelect:
      return <ReduxDropdownMultiSelect filter={filter} table={table} />
    default:
      return <small>Error rendering table filter</small>
  }
}

export default TableFilterItem