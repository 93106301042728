import { Checkbox, FormLabel, Stack } from "@mui/material";
import { getFilterByTableAndKey } from "app/main/apps/store/selectors/tableFilterSelector";
import { addFilter, removeFilter } from "app/main/apps/store/tableFilterSlice";
import { useAppDispatch } from "app/withAppProviders";
import { useState } from "react";
import { useSelector } from "react-redux";
import { sentenceCase } from "sentence-case";

function SearchCheckItemFilter({ filter, table }) {
  const dispatch = useAppDispatch();
  const {
    reduxObjectName,
    name,
    id,
    label,
    labelKey = "name",
    labelcode = "id",
    addtionalOptions = [],
  } = filter;
  const selectedValue = useSelector((state) =>
    getFilterByTableAndKey(state, { table, key: name })
  );
  const [value, setValue] = useState(null);

  const handleChange: any = (event) => {
    setValue(event.target.value);
    if (event.target.value) {
      dispatch(
        addFilter({
          key: table,
          filter: {
            key: name,
            value: event.target.value,
          },
        })
      );
    } else {
      dispatch(removeFilter({ key: table, filter: { key: name } }));
    }
  };

  return (
    <>
      <Stack
        direction="column"
        spacing={1}
        alignItems={"flex-start"}
        className="w-full"
      >
        <div
          className="flex w-full items-center justify-between"
        >
          <Stack direction="row" spacing={0} alignItems={"center"}>
            <Checkbox
              value={value ? "false" : "true"}
              onChange={handleChange}
              inputProps={{ "aria-label": "controlled" }}
            />
            <FormLabel id="demo-row-radio-buttons-group-label">
              {label || sentenceCase(name)}
            </FormLabel>
          </Stack>
        </div>
      </Stack>
    </>
  );
}

export default SearchCheckItemFilter;
