import { RouteConfig } from "app/shared/types";
import Dashboard from "./Dashboard";

const DashboardRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "dashboard",
      element: <Dashboard />,
      permissions: [],
    },
  ],
};

export default DashboardRouteConfig;
