import { Button, IconButton } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ListAltIcon from "@mui/icons-material/ListAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";

type TActionButton = {
  click: (id: number, extras?: any) => void;
  title: string;
};

function ActionButtonRenderer(actionButtons: TActionButton | TActionButton[]) {
  let actions;
  if (!Array.isArray(actionButtons)) {
    actions = [actionButtons as TActionButton];
  } else {
    actions = actionButtons as TActionButton[];
  }
  return (props: ICellRendererParams) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    return (
      <>
        {actions.map((action, index) => {
          switch (action.title) {
            case "Delete":
              return (
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    action.click(cellValue, props.data);
                  }}
                  key={index}
                >
                  <DeleteIcon />
                </IconButton>
              );
              break;
            case "Edit":
              return (
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    action.click(cellValue, props.data);
                  }}
                  key={index}
                >
                  <EditIcon />
                </IconButton>
              );
              break;
            case "Visibility":
              return (
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    action.click(cellValue, props.data);
                  }}
                  key={index}
                >
                  <VisibilityIcon />
                </IconButton>
              );
              break;
            case "Lists":
              return (
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    action.click(cellValue, props.data);
                  }}
                  key={index}
                >
                  <ListAltIcon />
                </IconButton>
              );
              break;
            case "Add":
              return (
                <IconButton
                  aria-label={action.title}
                  onClick={() => {
                    action.click(cellValue, props.data);
                  }}
                  key={index}
                >
                  <AddIcon />
                </IconButton>
              );
              break;
            case "Purchase":
              return (
                <Button
                  variant="contained"
                  onClick={() => {
                    action.click(cellValue, props.data);
                  }}
                  key={index}
                >
                  {action.title}
                </Button>
              );
              break;
            case "CampaignStartStop":
              if (props.data.status === "pending")
                return (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      action.click(cellValue, props.data);
                    }}
                    key={index}
                  >
                    {"Start"}
                  </Button>
                );
              else if (props.data.status === "paused")
                return (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      action.click(cellValue, props.data);
                    }}
                    key={index}
                  >
                    {"Re-start"}
                  </Button>
                );
              else if (props.data.status === "running")
                return (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      action.click(cellValue, props.data);
                    }}
                    key={index}
                  >
                    {"Pause"}
                  </Button>
                );
              else if (props.data.status === "completed") return "Completed";
              else return "Unknown";
              break;
            case "Stop":
              return (
                <Button
                  variant="contained"
                  className="mx-3"
                  size="small"
                  onClick={() => {
                    action.click(cellValue, props.data);
                  }}
                  key={index}
                >
                  {action.title}
                </Button>
              );
              break;
            default:
              return (
                <Button
                  onClick={() => {
                    action.click(cellValue, props.data);
                  }}
                  key={index}
                >
                  {action.title}
                </Button>
              );
              break;
          }
        })}
      </>
    );
  };
}

export default ActionButtonRenderer;
