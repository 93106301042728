import { RouteConfig } from "../../../shared/types";
import UserProfile from "./userProfile/UserProfile";

const UserProfileRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "userprofile",
      element: <UserProfile />,
      permissions: [""],
    },
  ],
};

export default UserProfileRouteConfig;
