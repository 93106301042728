const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    url: "dashboard",
  },
  {
    id: "user",
    title: "Users",
    type: "item",
    url: "user",
    permissions: ["admin"],
  },
  {
    id: "numbers/list",
    title: "Contact Lists",
    type: "item",
    url: "numbers/list",
  },
  {
    id: "campaign",
    title: "Campaigns",
    type: "item",
    url: "campaign",
  },
  {
    id: "twilio",
    title: "Twilio",
    type: "collapse",
    url: "twilio",
    children: [
      {
        id: "twilio-dashboard",
        title: "Twilio Numbers",
        type: "item",
        url: "twilio/numbers",
      },
      {
        id: "twilio-purchase",
        title: "Purchase Numbers",
        type: "item",
        url: "twilio/purchase",
      },
      {
        id: "twilio-setting",
        title: "Twilio Settings",
        type: "item",
        url: "twilio/settings",
      },
    ],
  },
  {
    id: "logs",
    title: "Logs",
    type: "item",
    url: "logs",
  },

  // {
  //   id: "setting-crontime",
  //   title: "Settings",
  //   type: "item",
  //   url: "setting/crontime",
  // },
];

export default navigationConfig;
