import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";
import { closeDialog, EDialogType } from "../../store/dialogSlice";

export type User = {
  id: number;
  file: string;
};

export const fetchUser = createAsyncThunk(
  "user/fetch",
  async (filters: any) => {
    const response = await axios.get("/user");
    return response.data;
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (user: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { name } = user;

    const body = {
      name
    }

    const url = `/user`;

    try {
      const response = await axios.post(url, body);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      dispatch(fetchUser({}));
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const changePassword = createAsyncThunk(
  "/reset-password",
  async (userInfo: any, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const { password, currentPassword } = userInfo;

    const body = {
      password: password,
      current_password: currentPassword
    }

    try {
      const response = await axios.post(`/change-password`, body);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      dispatch(closeDialog({ key: EDialogType.UserProfile }));
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

const initialState: any = {
  items: {} || [],
  editing: false,
  deleting: false,
  filter: {},
  export: false,
  sidebar: false,
};

const userProfileSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    startDeleting: (state, action) => {
      state.deleting = action.payload;
    },
    startEditing: (state, action) => {
      state.editing = action.payload;
    },
    stopEditing: (state) => {
      state.editing = false;
    },
    startExport: (state) => {
      state.export = true;
    },
    stopExport: (state) => {
      state.export = false;
    },
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
  },
  extraReducers: {
    [`${updateUser.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex(
        (it) => it.id === action.payload.data.id
      );
      if (index >= 0) {
        state.items[index] = action.payload.data;
      }
    },
    [`${fetchUser.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
    },
  },
});

export const {
  startDeleting,
  startEditing,
  stopEditing,
  startExport,
  stopExport,
  toggleSidebar,
} = userProfileSlice.actions;
export default userProfileSlice.reducer;
