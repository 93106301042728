import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";

export type Twilio = {
  id: number;
};

export type TwilioAuthKeys = {
  twilio_account_sid: string;
  twilio_auth_token: string;
};

export type TwilioState = {
  items: Twilio[];
  keys: TwilioAuthKeys;
  isCampaignAdded: boolean;
  isNumberAdded: boolean;
  isPurchaseNumberAdded: boolean;
  isTwilioDetailsAdded: boolean;
  editing: number | boolean;
  deleting: number | boolean;
  filter: { [key: string]: number };
  export: boolean;
  sidebar: boolean;
};

export const createTwilio = createAsyncThunk(
  "twilio/create",
  async (twilio: Twilio, thunkAPI) => {
    const { dispatch } = thunkAPI;
    const url = twilio.id ? `/twilio/number/${twilio.id}` : "/twilio/number";

    try {
      const response = await axios.post(url, twilio);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const addAccount = createAsyncThunk(
  "twilio/setting",
  async (w: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    const { twilio_auth_token, twilio_account_sid } = w;

    const body = {
      twilio_auth_token,
      twilio_account_sid,
    };

    const url = "/twilio/setting";

    try {
      const response = await axios.post(url, body);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const fetchTwilioSetting = createAsyncThunk(
  "twilio/setting/fetch",
  async (filters: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const response = await axios.get("/twilio/setting");
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

export const fetchTwilio = createAsyncThunk(
  "twilio/fetch",
  async (filters: any) => {
    const filter = filters.reduce((pv, cv) => {
      pv[cv.key] = cv.value;
      return pv;
    }, {});
    const response = await axios.get("/twilio/number", {
      params: {
        filter,
      },
    });
    return response.data;
  }
);

export const deleteTwilio = createAsyncThunk(
  "twilio/delete",
  async (id: number, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const response = await axios.delete(`/twilio/number/${id}`);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return { id };
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

const initialState: TwilioState = {
  items: [],
  keys: {
    twilio_account_sid: "",
    twilio_auth_token: "",
  },
  isCampaignAdded: false,
  isNumberAdded: false,
  isPurchaseNumberAdded: false,
  isTwilioDetailsAdded: false,
  deleting: false,
  editing: false,
  filter: {},
  export: false,
  sidebar: false,
};

const twilioSlice = createSlice({
  name: "twilio",
  initialState,
  reducers: {
    startEditing: (state, action) => {
      state.editing = action.payload;
    },
    startDeleting: (state, action) => {
      state.deleting = action.payload;
    },
    stopEditing: (state) => {
      state.editing = false;
    },
    startExport: (state) => {
      state.export = true;
    },
    stopExport: (state) => {
      state.export = false;
    },
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
    clearTwilioDetails: (state) => {
      state.keys = {
        twilio_account_sid: "",
        twilio_auth_token: "",
      };
    },
  },
  extraReducers: {
    [`${createTwilio.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex(
        (it) => it.id === action.payload.data.id
      );
      if (index >= 0) {
        state.items[index] = action.payload.data;
      } else {
        state.items.push(action.payload.data);
      }
    },

    [`${fetchTwilioSetting.fulfilled}`]: (state, action) => {
      state.keys = { ...action.payload.data };
    },
    [`${addAccount.fulfilled}`]: (state, action) => {
      state.keys = { ...action.payload.data };
      state.isCampaignAdded = action.payload.data.is_campaign_added;
      state.isNumberAdded = action.payload.data.is_number_added;
      state.isPurchaseNumberAdded =
        action.payload.data.is_purchase_number_added;
      state.isTwilioDetailsAdded = action.payload.data.is_twilio_details_added;
    },
    [`${fetchTwilio.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
    },

    [`${deleteTwilio.fulfilled}`]: (state, action) => {
      const index = state.items.findIndex((it) => it.id === action.payload.id);
      state.items.splice(index, 1);
    },
  },
});

export const {
  startEditing,
  startDeleting,
  stopEditing,
  startExport,
  stopExport,
  toggleSidebar,
  clearTwilioDetails,
} = twilioSlice.actions;
export default twilioSlice.reducer;
