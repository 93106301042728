import { TextField, Typography } from "@mui/material";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { sentenceCase } from "sentence-case";
import SFError from "../SFError";

export default function FileUpload(props) {
  const {
    type,
    value,
    handleChange,
    error,
    touched,
    handleBlur,
    setFieldValue,
    name,
    label,
    id,
    inputProps,
    reduxObjectName,
    options,
    fields,
  } = props;

  const onFileChange = (file) => {
    // upload and get s3 url
    const evt = { target: { name: name, value: file } };
    handleChange(evt);
  };

  return (
    <>
      <Typography variant="caption" display="block" gutterBottom>
        {label || sentenceCase(name)}
      </Typography>

      <TextField
        key={name}
        margin="dense"
        id={id || name}
        label={label || sentenceCase(name)}
        type="text/csv"
        fullWidth
        value={value}
        name={name}
        onChange={handleChange}
        variant="standard"
        onBlur={handleBlur}
        hidden
      />
      <FileUploader
        handleChange={onFileChange}
        multiple={false}
        name={`fu_${name}`}
        label={"Upload or drop a file right here"}
        types={["CSV"]}
      />
      <SFError>{error && touched && error}</SFError>
    </>
  );
}
