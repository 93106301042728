import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "app/withAppProviders";
import { useEffect } from "react";
import { ETable, registerFilter } from "../../store/tableFilterSlice";
import LogsHead from "./LogsHead";
import LogListsTable from "./LogListsTable";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
  },
  "& .FusePageSimple-content": {
    display: "flex",
  },
  "& .FusePageSimple-contentCard": {
    overflow: "hidden",
  },
}));

function Logs() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(registerFilter({ key: ETable.Logs }));
  }, [dispatch]);

  return <Root header={<LogsHead />} content={<LogListsTable />} innerScroll />;
}

export default Logs;
