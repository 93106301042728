import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import SFForm, { TSFForm } from "app/shared/components/SFForm/SFForm";
import { FieldType } from "app/shared/components/SFForm/SFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import { motion } from "framer-motion";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EDialogType, openDialog } from "../../store/dialogSlice";
import * as Yup from "yup";
import { fetchUser, startEditing, updateUser } from "../store/userProfileSlice";
import { Button, Stack } from "@mui/material";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
  },
  "& .FusePageSimple-content": {
    display: "flex",
  },
  "& .FusePageSimple-contentCard": {
    overflow: "hidden",
  },
}));

function UpdateUserProfileForm() {
  const [value] = useState<Moment | null>(moment());
  const user = useSelector((state: any) => state.user.items);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUser({}));
  }, [dispatch]);

  const onSubmit = async (w) => {
    dispatch(updateUser(w));
  };

  const form: TSFForm = {
    onSubmit,
    fields: [
      {
        name: "name",
        label: "User Name",
        type: FieldType.Text,
        value: user.name || "",
        validation: Yup.string()
          .required("user name is required")
          .typeError("Required!"),
      },
    ],
    resetFormOnSubmit: true,
  };

  return (
    <Root
      content={
        <>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
          >
            <Typography
              color="inherit"
              className="text-32 sm:text-14 font-semibold leading-tight widget flex w-full p-12"
            >
              User Information
            </Typography>
            <div className="ml-10 mx-5">
              <Grid container spacing={4}>
                <Grid item xs={6} md={12} sm={12} lg={12}>
                  <SFForm {...form}></SFForm>
                </Grid>
              </Grid>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
          >
            <Stack direction="column" spacing={4}>
              <Stack direction="row" spacing={4} alignItems={"center"}>
                <Button
                  className="mt-5 ml-10"
                  onClick={() => {
                    dispatch(startEditing(user.id));
                    dispatch(openDialog({ key: EDialogType.UserProfile }));
                  }}
                  variant="contained"
                >
                  Change Password
                </Button>
              </Stack>
            </Stack>
          </motion.div>
        </>
      }
      innerScroll
    />
  );
}

export default UpdateUserProfileForm;
