import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export type ListingNumbers = {
  id: number;
};

export type ListingNumbersState = {
  items: ListingNumbers[];
  editing: number | boolean;
  deleting: number | boolean;
  filter: { [key: string]: number };
  export: boolean;
  sidebar: boolean;
};

export const fetchCampaignListingNumber = createAsyncThunk(
  "CampaignListingNumber/fetch",
  async (filters: any) => {
    const filter = filters.reduce((pv, cv) => {
      pv[cv.key] = cv.value;
      return pv;
    }, {});

    const response = await axios.get("/campaign/listing-numbers");
    return response.data;
  }
);

const initialState: ListingNumbersState = {
  items: [],
  deleting: false,
  editing: false,
  filter: {},
  export: false,
  sidebar: false,
};

const listingNumbersSlice = createSlice({
  name: "listingNumber",
  initialState,
  reducers: {
    startEditing: (state, action) => {
      state.editing = action.payload;
    },
    startDeleting: (state, action) => {
      state.deleting = action.payload;
    },
    stopEditing: (state) => {
      state.editing = false;
    },
    startExport: (state) => {
      state.export = true;
    },
    stopExport: (state) => {
      state.export = false;
    },
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
  },
  extraReducers: {
    [`${fetchCampaignListingNumber.fulfilled}`]: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const {
  startEditing,
  startDeleting,
  stopEditing,
  startExport,
  stopExport,
  toggleSidebar,
} = listingNumbersSlice.actions;
export default listingNumbersSlice.reducer;
