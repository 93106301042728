import { yupResolver } from "@hookform/resolvers/yup";
import { styled, darken } from "@mui/material/styles";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import _ from "@lodash";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import { resetPassword } from "./store/forgotpasswordSlice";
import { useParams } from "react-router-dom";

const Root = styled("div")(({ theme }) => ({
  "& .Register3-leftSection": {},
  "& .Register3-rightSection": {
    background: `linear-gradient(to right, ${
      theme.palette.primary.dark
    } 0%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
    color: theme.palette.primary.contrastText,
  },
}));

/**
 * Form Validation Schema
 */

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter your password.")
    .min(8, "Password is too short - should be 8 chars minimum."),
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const defaultValues = {
  password: "",
  passwordConfirm: "",
};

function ForgotPassword(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const params = useParams();
  // you can find all params from here
  const token = params.token;

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, dirtyFields, errors } = formState;

  const onSubmit = async (userInfo: any) => {
    userInfo.token = token;
    dispatch(resetPassword(userInfo));
    navigate("/login");
  };

  return (
    <Root className="flex flex-col flex-auto items-center justify-center shrink-0 p-16 md:p-24">
      <motion.div
        initial={{ opacity: 0, scale: 0.6 }}
        animate={{ opacity: 1, scale: 1 }}
        className="flex w-full max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden"
      >
        <Card
          className="Register3-leftSection  flex flex-col w-full max-w-sm items-center justify-center shadow-0"
          square
        >
          <CardContent className="flex flex-col items-center justify-center w-full py-96 max-w-320">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              <div className="flex items-center mb-48">
                <img
                  className="logo-icon w-100 h-60"
                  src="assets/images/logo1.png"
                  alt="logo"
                />
              </div>
            </motion.div>

            <form
              name="forgotpassordform"
              noValidate
              className="flex flex-col justify-center w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-16 mt-7"
                    label="New Password"
                    type="password"
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <Controller
                name="passwordConfirm"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    className="mb-16 mt-7"
                    label="New Password (Confirm)"
                    type="password"
                    error={!!errors.passwordConfirm}
                    helperText={errors?.passwordConfirm?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />

              <Button
                variant="contained"
                color="primary"
                className="w-full mx-auto mt-16"
                disabled={_.isEmpty(dirtyFields) || !isValid}
                type="submit"
              >
                Reset Password
              </Button>
            </form>
          </CardContent>
        </Card>

        <div className="Register3-rightSection hidden md:flex flex-1 items-center justify-center p-64">
          <div className="max-w-320">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
            >
              <Typography
                color="inherit"
                className="text-32 sm:text-44 font-semibold leading-tight"
              >
                Reset Password
              </Typography>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3 } }}
            >
              <Typography
                variant="subtitle1"
                color="inherit"
                className="mt-32 font-medium"
              >
                Thank you for choosing Textky. We make it easy to reach the
                right customers with the right message at the right time.
                Experience our flexible automation options for texting
                campaigns. Plus, with our customizable templates, you can create
                eye-catching campaigns in minutes. Let's get started and set up
                is a breeze!
              </Typography>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </Root>
  );
}

export default ForgotPassword;
