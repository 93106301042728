import { combineReducers } from "@reduxjs/toolkit";
import auth from "app/login/store";
import campaignSlice from "app/main/apps/campaign/store/campaignSlice";
import twilioSlice from "app/main/apps/twilio/store/twilioSlice";
import dashboardSlice from "app/main/dashboard/store/dashboardSlice";
import numberSlice from "app/main/apps/number/store/numberSlice";
import settingSlice from "app/main/apps/setting/store/settingSlice";
import categorySlice from "app/main/apps/store/categorySlice";
import dialogSlice from "app/main/apps/store/dialogSlice";
import tableFilterSlice from "app/main/apps/store/tableFilterSlice";
import fuse from "./fuse";
import i18n from "./i18nSlice";
import useProfileSlice from "app/main/apps/user/store/userProfileSlice";
import peopleSlice from "app/main/apps/people/store/peopleSlice";
import enumSlice from "app/main/apps/store/enumSlice";
import forgotpasswordSlice from "app/forgotpassword/store/forgotpasswordSlice";
import twilioPurchaseSlice from "app/main/apps/twilio/store/twilioPurchaseSlice";
import verificationSlice from "app/main/apps/verification/store/verificationSlice";
import tagSlice from "app/main/apps/campaign/store/tagSlice";
import twiliocountrycodeSlice from "app/main/apps/twilio/store/twiliocountrycodeSlice";
import logsSlice from "app/main/apps/logs/store/logsSlice";
import listingNumbersSlice from "app/main/apps/twilio/store/listingNumbersSlice";
import subscriptionSlice from "app/main/apps/Subscription/subscription/store/subscriptionSlice";

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    auth,
    fuse,
    i18n,
    enum: enumSlice,
    campaign: campaignSlice,
    twilio: twilioSlice,
    twiliopurchase: twilioPurchaseSlice,
    number: numberSlice,
    setting: settingSlice,
    people: peopleSlice,
    category: categorySlice,
    dialog: dialogSlice,
    tableFilter: tableFilterSlice,
    verification: verificationSlice,
    forgotpassword: forgotpasswordSlice,
    tag: tagSlice,
    dashboard: dashboardSlice,
    countrycode: twiliocountrycodeSlice,
    user: useProfileSlice,
    logs: logsSlice,
    subscription: subscriptionSlice,
    listingNumber: listingNumbersSlice,
    ...asyncReducers,
  });

  /*
  Reset the redux store when user logged out
   */
  if (action.type === "auth/user/userLoggedOut") {
    // state = undefined;
  }

  return combinedReducer(state, action as never);
};

export default createReducer;
