import FuseSplashScreen from "@fuse/core/FuseSplashScreen";
import { bindActionCreators } from "@reduxjs/toolkit";
import jwtService from "app/services/jwtService";
import { hideMessage, showMessage } from "app/store/fuse/messageSlice";
import { Component } from "react";
import { connect } from "react-redux";
import { logoutUser, setUserData } from "app/login/store/userSlice";

class Auth extends Component {
  props;
  state = {
    waitAuthCheck: true,
  };

  private jwtCheckPromise: Promise<any> | null = null;

  componentDidMount() {
    if (!this.jwtCheckPromise) {
      this.jwtCheckPromise = Promise.all([
      ]).then(() => {
        this.setState({ waitAuthCheck: false });
      });

      return this.jwtCheckPromise;
    }
  }

  jwtCheck = () =>
    new Promise((resolve) => {
      jwtService.on("onAutoLogin", () => {
        jwtService
          .signInWithToken()
          .then((user) => {
            this.props.setUserData(user);

            resolve(null);
          })
          .catch((error) => {
            resolve(null);
          });
      });

      jwtService.on("onAutoLogout", (message) => {
        this.props.logout();
        resolve(null);
      });

      jwtService.on("onNoAccessToken", () => {
        resolve(null);
      });
      jwtService.init();
      resolve(null);
    });

  render() {
    return this.state.waitAuthCheck ? (
      <FuseSplashScreen />
    ) : (
      <>{this.props.children}</>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
      showMessage,
      hideMessage,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
