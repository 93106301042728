import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";

export type Subscription = {
  id: number;
  campaign_name: string;
  campaign_id: number;
};

export type SubscriptionState = {
  items: [];
  filter: { [key: string]: number };
  export: boolean;
  sidebar: boolean;
};

export const SubscriptionData = createAsyncThunk(
  "SubscriptionData/post",
  async (data: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const response = await axios.post("/", data);
      dispatch(
        showMessage({ message: response.data.message, variant: "success" })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

const initialState: SubscriptionState = {
  items: [],
  filter: {},
  export: false,
  sidebar: false,
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    startExport: (state) => {
      state.export = true;
    },
    stopExport: (state) => {
      state.export = false;
    },
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
  },
  extraReducers: {
    [`${SubscriptionData.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
    },
  },
});

export const { startExport, stopExport, toggleSidebar } =
  subscriptionSlice.actions;
export default subscriptionSlice.reducer;
