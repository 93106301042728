import { TextField } from "@mui/material";
import { getFilterByTableAndKey } from "app/main/apps/store/selectors/tableFilterSelector";
import { addFilter, removeFilter } from "app/main/apps/store/tableFilterSlice";
import { useAppDispatch } from "app/withAppProviders";
import { useState } from "react";
import { useSelector } from "react-redux";
import { sentenceCase } from "sentence-case";

function SearchTextItemFilter({ filter, table }) {
  const dispatch = useAppDispatch();
  const { reduxObjectName, name, id, label, labelKey = 'name', labelcode = "id", addtionalOptions = [] } = filter
  const selectedValue = useSelector(state => getFilterByTableAndKey(state, { table, key: name }))
  const [value, setValue] = useState(null)

  const handleChange: any = (event) => {
    setValue(event.target.value)
    if (event.target.value) {
      dispatch(addFilter({
        key: table,
        filter: {
          key: name,
          value: event.target.value
        }
      }))
    } else {
      dispatch(removeFilter({ key: table, filter: { key: name } }))
    }
  }

  return <>
    <TextField
      className="mx-5"
      label={label || sentenceCase(name)}
      type="text"
      fullWidth
      name={name}
      onChange={handleChange}
      value={value}
      variant="outlined"
    />
  </>
}

export default SearchTextItemFilter;