import SFForm, { TSFForm } from "app/shared/components/SFForm/SFForm";
import { FieldType } from "app/shared/components/SFForm/SFFormItem";
import { useAppDispatch } from "app/withAppProviders";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import history from "@history";
import { fetchCampaignTagList } from "../../campaign/store/tagSlice";
import { createNumber } from "../store/numberSlice";
import { EDialogType, closeDialog } from "../../store/dialogSlice";

function AddNewNumberForm() {
  const number =
    useSelector((state: any) =>
      state.number.items.find((log) => log.id === state.number.editing)
    ) || {};

  const [value] = useState<Moment | null>(moment());
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchCampaignTagList({}));
  }, []);

  const onSubmit = (uploadData) => {
    uploadData.id = number.id;
    dispatch(createNumber(uploadData)).then((res: any) => {
      dispatch(closeDialog({ key: EDialogType.Number }));
      const userDetails = { ...res.payload.data };
      if (userDetails.is_purchase_number_added === false) {
        history.push("twilio/purchase");
      } else if (userDetails.is_number_added === false) {
        history.push("/number/list");
      }
    });
  };

  const form: TSFForm = {
    onSubmit,
    fields: [
      {
        name: "fileUploadButton",
        label: "File Upload(csv)",
        type: FieldType.button,
      },
      {
        name: "campaign_name",
        label: "Add Name To Number List",
        type: FieldType.Text,
        value: "",
        validation: Yup.string().required(),
      },
      {
        name: "file",
        label: "File Upload(csv)",
        type: FieldType.FileUpload,
        value: number.number || "",
        validation: Yup.string().required(),
      },
    ],
    resetFormOnSubmit: true,
  };

  return <SFForm {...form}></SFForm>;
}

export default AddNewNumberForm;
