import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import SettingHead from "./SettingHead";
import SettingTable from "./SettingTable";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
  },
  "& .FusePageSimple-content": {
    display: "flex",
  },
  "& .FusePageSimple-contentCard": {
    overflow: "hidden",
  },
}));

function Setting() {
  return (
    <Root header={<SettingHead />} content={<SettingTable />} innerScroll />
  );
}

export default Setting;
