import { RouteConfig } from "../../../shared/types";
import Subscription from "./subscription/subscription";

const SubscriptionRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "subscription",
      element: <Subscription />,
      permissions: [""],
    },
  ],
};

export default SubscriptionRouteConfig;
