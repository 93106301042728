import FusePageSimple from "@fuse/core/FusePageSimple";
import { styled } from "@mui/material/styles";
import { useAppDispatch } from "app/withAppProviders";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getFiltersByTable } from "../../store/selectors/tableFilterSelector";
import { ETable, registerFilter } from "../../store/tableFilterSlice";
import { fetchTwilioAvaliableNumbers } from "../store/twilioPurchaseSlice";
import TwilioPurchaseHead from "./TwilioPurchaseHead";
import TwilioPurchaseTable from "./TwilioPurchaseTable";
import TwilioPurchaseToolbar from "./TwilioPurchaseToolbar";

const Root: any = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    minHeight: 72,
    height: 72,
    alignItems: "center",
  },
  "& .FusePageSimple-content": {
    display: "flex",
  },
  "& .FusePageSimple-contentCard": {
    overflow: "hidden",
  },
}));

function PurchaseNumber() {
  const dispatch = useAppDispatch();

  const filter = useSelector((state) =>
    getFiltersByTable(state, { table: ETable.TwilioPurchaseNumbers })
  );

  useEffect(() => {
    dispatch(registerFilter({ key: ETable.TwilioPurchaseNumbers }));
    dispatch(fetchTwilioAvaliableNumbers(filter));
  }, [dispatch]);

  return (
    <Root
      contentToolbar={<TwilioPurchaseToolbar />}
      header={<TwilioPurchaseHead />}
      content={<TwilioPurchaseTable />}
      innerScroll
    />
  );
}

export default PurchaseNumber;
