import withRouter from "@fuse/core/withRouter";
import { ColDef, ColumnState, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";
import SFFabAction from "app/shared/components/SFFabAction";
import { useAppDispatch } from "app/withAppProviders";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { EDialogType, openDialog } from "../../store/dialogSlice";
import { getFiltersByTable } from "../../store/selectors/tableFilterSelector";
import { ETable } from "../../store/tableFilterSlice";
import {
  fetchTwilio,
  startDeleting,
  stopEditing,
  stopExport
} from "../store/twilioSlice";

function TwilioTable(props) {

  const dispatch = useAppDispatch();
  const rowData = useSelector((state: any) => state.twilio.items);
  const [data, setData] = useState(rowData);

  const filter = useSelector((state) =>
    getFiltersByTable(state, { table: ETable.Twilio })
  );

  const shouldExport = useSelector((state: any) => state.twilio.export);
  const gridRef = useRef<AgGridReact<any>>(null)

  useEffect(() => {
    if (gridRef && gridRef.current && shouldExport) {
      gridRef.current.api.exportDataAsCsv({
        fileName: "twilio.csv",
        columnKeys: columnDefs
          .filter((col) => col.field !== "id")
          .map((col) => `${col.field}`),
      });
      dispatch(stopExport());
    }
  }, [shouldExport]);

  useEffect(() => {
    dispatch(fetchTwilio(filter));
  }, [dispatch, filter]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    { field: "id" },
    { field: "number" },

    {
      field: "id",
      cellRenderer: ActionButtonRenderer([
        {
          click: (id) => {
            dispatch(startDeleting(id))
            dispatch(openDialog({ key: EDialogType.TwilioNumberConfirmation }));
          },
          title: "Delete",
        },
      ]),
      headerName: "Actions",
    },
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    var defaultSortModel: ColumnState[] = [
      { colId: "date", sort: "desc", sortIndex: 0 },
      { colId: "id", sort: "desc", sortIndex: 1 },
    ];
    params.columnApi.applyColumnState({ state: defaultSortModel });

    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 130,
      columnLimits: [{ key: "task", minWidth: 300 }],
    });
  }, []);

  return (
    <>
      <div className="ag-theme-alpine w-full">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          multiSortKey={"ctrl"}
          sideBar={"filters"}
          enableCellTextSelection={true}
        ></AgGridReact>
      </div>

      <SFFabAction
        onClick={() => {
          dispatch(stopEditing());
          dispatch(openDialog({ key: EDialogType.Twilio }));
        }}
      ></SFFabAction>
    </>
  );
}

TwilioTable.propTypes = {};

export default withRouter(TwilioTable);
