import moment from "moment";

export const DisplayFormikState = (props) =>
  process.env.NODE_ENV === "development" ? (
    <div className="helper-div-custom">
      <strong>Injected Formik props (the form's state)</strong>
      <div>
        <code>touched:</code> {JSON.stringify(props.touched, null, 2)}
      </div>
      <div>
        <code>errors:</code> {JSON.stringify(props.errors, null, 2)}
      </div>
      <div>
        <code>values:</code> {JSON.stringify(props.values, null, 2)}
      </div>
      <div>
        <code>isSubmitting:</code> {JSON.stringify(props.isSubmitting, null, 2)}
      </div>
    </div>
  ) : (
    <></>
  );

export const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("-");
    var cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  },
};

export const dateFormater = (date, format = "LL") => {
  return date ? moment(date).format(format) : "";
};
