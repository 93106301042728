import withRouter from "@fuse/core/withRouter";
import { ColDef, ColumnState, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import ActionButtonRenderer from "app/shared/components/CellRenderers/ActionButtonRenderer";
import SFFabAction from "app/shared/components/SFFabAction";
import { dateFilterParams } from "app/shared/helper";
import { useAppDispatch } from "app/withAppProviders";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { EDialogType, openDialog } from "../../../store/dialogSlice";
import { getFiltersByTable } from "../../../store/selectors/tableFilterSelector";
import { ETable } from "../../../store/tableFilterSlice";
import { fetchPeople, startEditing, stopEditing, stopExport } from "../../store/peopleSlice";

function PeopleTable(props) {
  const dispatch = useAppDispatch()
  const rowData = useSelector((state: any) => state.people.items);
  const filter = useSelector(state => getFiltersByTable(state, { table: ETable.People }));
  const shouldExport = useSelector((state: any) => state.people.export);
  const gridRef = useRef<AgGridReact<any>>(null)

  useEffect(() => {
    dispatch(fetchPeople(filter))
  }, [dispatch, filter])

  useEffect(() => {
    if (gridRef && gridRef.current && shouldExport) {
      gridRef.current.api.exportDataAsCsv({
        fileName: 'People.csv',
        columnKeys: columnDefs.filter(col => col.field != 'id').map(col => `${col.field}`)
      });
      dispatch(stopExport())
    }
  }, [shouldExport])

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState<ColDef[]>([
    { field: 'uid', headerName: "Employee ID", filter: true },
    { field: 'name', filter: true },
    { field: 'email' },
    { field: 'gender', filter: true },
    { field: 'displayDesignation', headerName: 'Designation', filter: true },
    { field: 'status', headerName: 'Employement Status', filter: true },
    { field: 'userType', filter: true },
    {
      field: 'id', cellRenderer: ActionButtonRenderer({
        click: (id, extra) => {
          dispatch(startEditing(id))
          dispatch(openDialog({ key: EDialogType.People, title: `${extra.name} / Primary Details` }))
        }, title: "Edit"
      }),
      headerName: "Actions"
    }
  ])

  const onGridReady = useCallback((params: GridReadyEvent) => {
    var defaultSortModel: ColumnState[] = [
      { colId: 'uid', sort: 'desc', sortIndex: 1 },
    ];
    params.columnApi.applyColumnState({ state: defaultSortModel });

    gridRef.current!.api.sizeColumnsToFit({
      defaultMinWidth: 130,
      columnLimits: [{ key: 'task', minWidth: 300 }],
    });
  }, []);

  const onFirstDataRendered = useCallback(() => {
    const statusFilterComponent = gridRef.current!.api.getFilterInstance('status');
    statusFilterComponent!.setModel({
      type: 'equals',
      filter: 'Active'
    });

    const userTypeFilterComponent = gridRef.current!.api.getFilterInstance('userType');
    userTypeFilterComponent!.setModel({
      type: 'equals',
      filter: 'Employee'
    });
    gridRef.current!.api.onFilterChanged();
  }, []);

  return <>
    <div className="ag-theme-alpine w-full">
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        multiSortKey={'ctrl'}
        sideBar={'filters'}
        enableCellTextSelection={true}
      >
      </AgGridReact>
    </div>

    <SFFabAction onClick={() => {
      dispatch(stopEditing())
      dispatch(openDialog({ key: EDialogType.People }))
    }}></SFFabAction>
  </>
}

PeopleTable.propTypes = {

};

export default withRouter(PeopleTable);