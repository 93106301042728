import { RouteConfig } from "../../../shared/types";
import Setting from "./settings/Setting";

const SettingRouteConfig: RouteConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "setting/crontime",
      element: <Setting />,
      permissions: [""],
    },
  ],
};

export default SettingRouteConfig;
