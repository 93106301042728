import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { showMessage } from "app/store/fuse/messageSlice";
import axios from "axios";

export type TCampaignTag = {
  id: number;
  tag: string;
};

export type TCampaignTagState = {
  items: any[];
  filter: { [key: string]: number };
  export: boolean;
  sidebar: boolean;
};

export const fetchCampaignTagList = createAsyncThunk(
  "campaigntag/fetch",
  async (filters: any, thunkAPI) => {
    const { dispatch } = thunkAPI;

    try {
      const response = await axios.get("/campaign/comman-tag-list");
      return response.data;
    } catch (err: any) {
      dispatch(
        showMessage({ message: err.response.data.error, variant: "error" })
      );
    }
  }
);

const initialState: TCampaignTagState = {
  items: [],
  filter: {},
  export: false,
  sidebar: false,
};

const tagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {},

  extraReducers: {
    [`${fetchCampaignTagList.fulfilled}`]: (state, action) => {
      state.items = action.payload.data;
    },
  },
});

export const {} = tagSlice.actions;
export default tagSlice.reducer;
